/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { auth, db } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "@firebase/firestore";
import { useNavigate } from "react-router-dom";

const currencies = ["Employée ?"];
const navigation = {
  categories: [
    {
      name: "MorServ",
      featured: [
        {
          name: "Plomberie",
          lin: "plomberie",
          href: "plomberie",
          imageSrc: "../images/serv/plomb.jpg",
          imageAlt:
            "Models sitting back to back, wearing Basic Tee in black and bone.",
        },
        {
          name: "Maçonnerie",
          lin: "maconnerie",
          href: "maconnerie",
          imageSrc: "../images/serv/macon.jpg",
          imageAlt:
            "Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.",
        },
        {
          name: "Placo",
          lin: "placo",
          href: "placo",
          imageSrc: "../images/serv/placo.jpg",
          imageAlt:
            "Model wearing minimalist watch with black wristband and white watch face.",
        },
        {
          name: "Electricité",
          lin: "electric",
          href: "electric",
          imageSrc: "../images/serv/elec.jpg",
          imageAlt:
            "Model opening tan leather long wallet with credit card pockets and cash pouch.",
        },
        {
          name: "Froid",
          lin: "froid",
          href: "froid",
          imageSrc: "../images/serv/froi.jpg",
          imageAlt:
            "Model opening tan leather long wallet with credit card pockets and cash pouch.",
        },
        {
          name: "Peinture",
          lin: "peinture",
          href: "peinture",
          imageSrc: "../images/serv/peint.jpg",
          imageAlt:
            "Model opening tan leather long wallet with credit card pockets and cash pouch.",
        },
        {
          name: "Nettoyage",
          lin: "nettoyage",
          href: "nettoyage",
          imageSrc: "../images/serv/net.jpg",
          imageAlt:
            "Model opening tan leather long wallet with credit card pockets and cash pouch.",
        },
        {
          name: "Coursa",
          lin: "livraison",
          href: "livraison",
          imageSrc: "../images/serv/coursa.jpg",
          imageAlt:
            "Model opening tan leather long wallet with credit card pockets and cash pouch.",
        },
      ],
    },
    {
      name: "MorPub",
      featured: [
        {
          name: "Web Développement ",
          href: "web",
          imageSrc: "../images/pub/site.jpg",
          imageAlt:
            "Hats and sweaters on wood shelves next to various colors of t-shirts on hangers.",
        },
        {
          name: "AudioVisuel",
          href: "audio",
          imageSrc: "../images/pub/vid.jpg",
          imageAlt: "Model wearing light heather gray t-shirt.",
        },
        {
          name: "Impression & Publicité",
          href: "print",
          imageSrc: "../images/pub/print.jpg",
          imageAlt:
            "Grey 6-panel baseball hat with black brim, black mountain graphic on front, and light heather gray body.",
        },
      ],
    },
  ],
  pages: [{ name: "Entreprise", href: "/company" }],
};
const categories = [
  {
    name: "Plomberie",
    lin: "plomberie",
    href: "plomberie",
    imageSrc: "../images/serv/plomb.jpg",
  },
  {
    name: "Maçonnerie",
    lin: "maconnerie",
    href: "maconnerie",
    imageSrc: "../images/serv/macon.jpg",
  },
  {
    name: "Placo",
    lin: "placo",
    href: "placo",
    imageSrc: "../images/serv/placo.jpg",
  },
  {
    name: "Electricité",
    lin: "electric",
    href: "electric",
    imageSrc: "../images/serv/elec.jpg",
  },
  {
    name: "Froid",
    lin: "froid",
    href: "froid",
    imageSrc: "../images/serv/froi.jpg",
  },
];
const collections = [
  {
    name: "Web Développement ",
    lin: "web",
    href: "web",
    imageSrc: "../images/pub/site.jpg",
    imageAlt:
      "Hats and sweaters on wood shelves next to various colors of t-shirts on hangers.",
    description:
      "Créez un Monde Digital Sans Limites avec Nous Bâtissons Votre Succès en Ligne Ensemble ",
  },
  {
    name: "AudioVisuel",
    lin: "audio",
    href: "audio",
    imageSrc: "../images/pub/vid.jpg",
    imageAlt: "Model wearing light heather gray t-shirt.",
    description:
      "Créez un Monde Digital Sans Limites avec Nous Bâtissons Votre Succès en Ligne Ensemble ",
  },
  {
    name: "Impression",
    lin: "print",
    href: "print",
    imageSrc: "../images/pub/print.jpg",
    imageAlt:
      "Grey 6-panel baseball hat with black brim, black mountain graphic on front, and light heather gray body.",
    description:
      "Créez un Monde Digital Sans Limites avec Nous Bâtissons Votre Succès en Ligne Ensemble ",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavBar2 = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Utilisateur connecté, récupérons les données de Firestore en temps réel
        const userRef = collection(db, "users");
        const q = query(userRef, where("userId", "==", user.uid));

        const unsubscribeSnapshot = onSnapshot(q, (snapshot) => {
          snapshot.forEach((doc) => {
            // Nous avons trouvé l'utilisateur dans la collection
            const userData = doc.data();
            setUser(userData); // Mise à jour de l'état avec toutes les données
          });
        });

        // Vous pouvez également vous désabonner lorsque le composant est démonté
        return () => unsubscribeSnapshot();
      } else {
        // L'utilisateur n'est pas connecté
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate("/login");
    });
  };
  const [userCart, setUserCart] = useState([]);
  const [set, setUserId] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("Utilisateur connecté avec l'ID :", user.uid);
        setUserId(user.uid);
        fetchUserCart(user.uid);
      } else {
        console.log("Aucun utilisateur connecté.");
        setUserId(null);
        setUserCart([]);
      }
    });
    return unsubscribe;
  }, []);

  const fetchUserCart = async (userId) => {
    try {
      console.log("Fetching user cart for userId:", userId);
      const cartQuery = query(
        collection(db, "carts"),
        where("userId", "==", userId)
      );
      const unsubscribe = onSnapshot(cartQuery, (querySnapshot) => {
        const userCartData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserCart(userCartData);
      });
      return unsubscribe; // Assurez-vous de retourner la fonction de désinscription
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du panier de l'utilisateur :",
        error
      );
    }
  };

  return (
    <div>
      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as="div" className="mt-2">
                  <div className="border-b border-gray-200">
                    <Tab.List className="-mb-px flex space-x-8 px-4">
                      {navigation.categories.map((category) => (
                        <Tab
                          key={category.nom}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? "text-indigo-600 border-indigo-600"
                                : "text-gray-900 border-transparent",
                              "flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium"
                            )
                          }
                        >
                          {category.name}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>

                  <Tab.Panels as={Fragment}>
                    {navigation.categories.map((category) => (
                      <Tab.Panel
                        key={category.name}
                        className="space-y-12 px-4 py-6"
                      >
                        <div className="grid grid-cols-2 gap-x-4 gap-y-10">
                          {category.featured.map((item) => (
                            <div key={item.name} className="group relative">
                              <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-md bg-gray-100 group-hover:opacity-75">
                                <img
                                  src={item.imageSrc}
                                  alt={item.imageAlt}
                                  className="object-cover object-center"
                                />
                              </div>
                              <a
                                href={`/offre/${item.lin}`}
                                className="mt-6 block text-sm font-medium text-gray-900"
                              >
                                <span
                                  className="absolute inset-0 z-10"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                              <p
                                aria-hidden="true"
                                className="mt-1 text-sm text-gray-500"
                              ></p>
                            </div>
                          ))}
                        </div>
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>

                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        href={page.href}
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>
                {!user && (
                  <>
                    <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                      <div className="flow-root">
                        <a
                          href="/signin"
                          className="-m-2 block p-2 font-medium text-gray-900"
                        >
                          Crée un Compte
                        </a>
                      </div>
                      <div className="flow-root">
                        <a
                          href="/login"
                          className="-m-2 block p-2 font-medium text-gray-900"
                        >
                          Log in
                        </a>
                      </div>
                    </div>
                  </>
                )}

                {user && (
                  <>
                    <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                      <div className="flow-root">
                        <a
                          href="/signin"
                          className="-m-2 block p-2 font-medium text-gray-900"
                        >
                          {user.fullName}
                        </a>
                      </div>
                      <div className="flow-root">
                        <div
                          onClick={() => {
                            handleLogout();
                          }}
                          className="-m-2 block p-2 font-medium text-gray-900"
                        >
                          Log out
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  {/* Currency selector */}
                  <form>
                    <div className="inline-block">
                      <label htmlFor="mobile-currency" className="sr-only">
                        Currency
                      </label>
                      <div className="group relative -ml-2 rounded-md border-transparent focus-within:ring-2 focus-within:ring-white">
                        <a>Employé ?</a>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Hero section */}
      <div className="relative bg-gray-900">
        {/* Decorative image and overlay */}
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <img
            src="../images/fd.jpg"
            alt=""
            className="h-full w-full object-cover object-center"
          />
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-0 bg-gray-900 opacity-50"
        />

        {/* Navigation */}
        <header className="relative z-10">
          <nav aria-label="Top">
            {/* Top navigation */}
            <div className="bg-gray-900">
              <div className="mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                {/* Currency selector */}

                <div>
                  <a
                    href="/inscrption"
                    className="flex items-center rounded-md border-transparent bg-gray-900 bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-white focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-100"
                  >
                    Employé ?
                  </a>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-300"
                      aria-hidden="true"
                    />
                  </div>
                </div>

                {!user && (
                  <>
                    <div className="flex items-center space-x-6">
                      <a
                        href="/signin"
                        className="text-sm font-medium text-white hover:text-gray-100"
                      >
                        Créer un compte
                      </a>

                      <a
                        href="/login"
                        className="text-sm font-medium text-white hover:text-gray-100"
                      >
                        Log in
                      </a>
                    </div>
                  </>
                )}

                {user && (
                  <>
                    <div className="flex items-center space-x-6">
                      <a
                        href="/signin"
                        className="text-sm font-medium text-white hover:text-gray-100"
                      >
                        {user.fullName}
                      </a>

                      <a
                        onClick={() => {
                          handleLogout();
                        }}
                        className="text-sm font-medium text-white hover:text-gray-100"
                      >
                        Log Out
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Secondary navigation */}
            <div className="bg-white bg-opacity-10 backdrop-blur-md backdrop-filter">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div>
                  <div className="flex h-16 items-center justify-between">
                    <div className="hidden lg:flex lg:flex-1 lg:items-center animate-pulse   animate-infinite">
                      <a href="/">
                        <span className="sr-only">Morserv</span>
                        <img
                          className="h-14 w-auto"
                          src="../images/logo2.png"
                          alt=""
                        />
                      </a>
                    </div>

                    <div className="hidden h-full lg:flex">
                      {/* Flyout menus */}
                      <Popover.Group className="inset-x-0 bottom-0 px-4">
                        <div className="flex h-full justify-center space-x-8">
                          {navigation.categories.map((category) => (
                            <Popover key={category.name} className="flex">
                              {({ open }) => (
                                <>
                                  <div className="relative flex">
                                    <Popover.Button className="relative z-10 flex items-center justify-center text-sm font-medium text-white transition-colors duration-200 ease-out">
                                      {category.name}
                                      <span
                                        className={classNames(
                                          open ? "bg-white" : "",
                                          "absolute inset-x-0 -bottom-px h-0.5 transition duration-200 ease-out"
                                        )}
                                        aria-hidden="true"
                                      />
                                    </Popover.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Popover.Panel className="absolute inset-x-0 top-full text-sm text-gray-500">
                                      {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                      <div
                                        className="absolute inset-0 top-1/2 bg-white shadow"
                                        aria-hidden="true"
                                      />

                                      <div className="relative bg-white">
                                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                          <div className="grid grid-cols-4 gap-y-10 gap-x-8 py-16">
                                            {category.featured.map((item) => (
                                              <div
                                                key={item.name}
                                                className="group relative"
                                              >
                                                <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-md bg-gray-100 group-hover:opacity-75">
                                                  <img
                                                    src={item.imageSrc}
                                                    alt={item.imageAlt}
                                                    className="object-cover object-center"
                                                  />
                                                </div>
                                                <a
                                                  href={`/offre/${item.lin}`}
                                                  className="mt-4 block font-medium text-gray-900"
                                                >
                                                  <span
                                                    className="absolute inset-0 z-10"
                                                    aria-hidden="true"
                                                  />
                                                  {item.name}
                                                </a>
                                                <p
                                                  aria-hidden="true"
                                                  className="mt-1"
                                                ></p>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </Popover.Panel>
                                  </Transition>
                                </>
                              )}
                            </Popover>
                          ))}

                          {navigation.pages.map((page) => (
                            <a
                              key={page.name}
                              href={page.href}
                              className="flex items-center text-sm font-me dium text-white"
                            >
                              {page.name}
                            </a>
                          ))}
                        </div>
                      </Popover.Group>
                    </div>

                    {/* Mobile menu and search (lg-) */}
                    <div className="flex flex-1 items-center lg:hidden">
                      <button
                        type="button"
                        className="-ml-2 p-2 text-white"
                        onClick={() => setMobileMenuOpen(true)}
                      >
                        <span className="sr-only">Open menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      </button>

                      {/* Search */}
                      <a href="/search" className="ml-2 p-2 text-white">
                        <span className="sr-only">Recherche</span>
                        <MagnifyingGlassIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </a>
                    </div>

                    {/* Logo (lg-) */}
                    <a
                      href="/"
                      className="lg:hidden animate-pulse animate-infinite"
                    >
                      <span className="sr-only">Morserv</span>
                      <img
                        src="../images/logo.png"
                        alt="morserv"
                        className="h-12 w-auto"
                      />
                    </a>

                    <div className="flex flex-1 items-center justify-end">
                      <a
                        href="/search"
                        className="hidden text-sm font-medium text-white lg:block"
                      >
                        Recherche
                      </a>

                      <div className="flex items-center lg:ml-8">
                        {/* Help */}
                        <a href="/contact" className="p-2 text-white lg:hidden">
                          <span className="sr-only">Contact</span>
                          <QuestionMarkCircleIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </a>
                        <a
                          href="/contact"
                          className="hidden text-sm font-medium text-white lg:block"
                        >
                          Contact
                        </a>

                        {/* Cart */}
                        <div className="ml-4 flow-root lg:ml-8">
                          <a
                            href="/cart"
                            className="group -m-2 flex items-center p-2"
                          >
                            <ShoppingBagIcon
                              className="h-6 w-6 flex-shrink-0 text-white"
                              aria-hidden="true"
                            />
                            <span className="ml-2 text-sm font-medium text-white">
                              {userCart.map((cart) => (
                                <div>{cart.items.length}</div>
                              ))}
                            </span>
                            <span className="sr-only">
                              items in cart, view bag
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </div>
  );
};
export default NavBar2;
