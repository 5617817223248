/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { collection, getDocs, onSnapshot, orderBy } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { db } from "../config/firebase";
import { query } from "firebase/database";
const currencies = ["CAD", "USD", "AUD", "EUR", "GBP"];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Related(props) {
  const related = props.prod;
  const [product, setForms] = useState([]);
  const formu = collection(db, "produits");

  useEffect(() => {
    const queryMessages = query(formu);
    const unsubscribe = onSnapshot(queryMessages, (snapshot) => {
      let offre = [];
      snapshot.forEach((doc) => {
        offre.push({ ...doc.data() });
      });
      const filteredOffre = offre.filter((item) => item.code === related);

      setForms(filteredOffre);

      console.log("Formsffffffffff updated:", related);
    });

    return () => {
      console.log("Unsubscribed");
      unsubscribe();
    };
  }, [related]);

  return (
    <div className="relative">
      {product.map((prod) => (
        <a href={`/offre/${prod.code}`}>
          <div className="relative h-72 w-full overflow-hidden  rounded-lg">
            <img
              src={prod.imageUrl}
              alt=""
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
            <div
              aria-hidden="true"
              className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
            />
            <p className="relative text-lg font-semibold text-white">
              {prod.nom}
            </p>
          </div>{" "}
        </a>
      ))}
    </div>
  );
}
