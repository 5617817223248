import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { auth, db } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  Bars3Icon,
  CurrencyDollarIcon,
  GiftIcon,
  LockClosedIcon,
  RocketLaunchIcon,
  ShieldCheckIcon,
  XMarkIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { collection, onSnapshot, query, where } from "@firebase/firestore";
import { useNavigate } from "react-router-dom";
import Footer2 from "../Components/Footer2";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

const navigation = {
  categories: [
    {
      name: "Moserv",
      featured: [
        {
          name: "Plomberie",
          lin: "plomberie",
          href: "/offre/plomberie",
          imageSrc: "./images/serv/plomb.jpg",
          imageAlt:
            "Models sitting back to back, wearing Basic Tee in black and bone.",
        },
        {
          name: "Maçonnerie",
          lin: "maconnerie",
          href: "/offre/maconnerie",
          imageSrc: "./images/serv/macon.jpg",
          imageAlt:
            "Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.",
        },
        {
          name: "Placo",
          lin: "placo",
          href: "/offre/placo",
          imageSrc: "./images/serv/placo.jpg",
          imageAlt:
            "Model wearing minimalist watch with black wristband and white watch face.",
        },
        {
          name: "Electricité",
          lin: "electrici",
          href: "/offre/electrici",
          imageSrc: "./images/serv/elec.jpg",
          imageAlt:
            "Model opening tan leather long wallet with credit card pockets and cash pouch.",
        },
        {
          name: "Froid",
          lin: "froid",
          href: "/offre/froid",
          imageSrc: "./images/serv/froi.jpg",
          imageAlt:
            "Model opening tan leather long wallet with credit card pockets and cash pouch.",
        },
        {
          name: "Peinture",
          lin: "peinture",
          href: "/offre/peinture",
          imageSrc: "./images/serv/peint.jpg",
          imageAlt:
            "Model opening tan leather long wallet with credit card pockets and cash pouch.",
        },
        {
          name: "Nettoyage",
          lin: "nettoyage",
          href: "/offre/nettoyage",
          imageSrc: "./images/serv/net.jpg",
          imageAlt:
            "Model opening tan leather long wallet with credit card pockets and cash pouch.",
        },
        {
          name: "Coursa",
          lin: "livraison",
          href: "/offre/livraison",
          imageSrc: "./images/serv/coursa.jpg",
          imageAlt:
            "Model opening tan leather long wallet with credit card pockets and cash pouch.",
        },
      ],
    },
    {
      name: "MorPub",
      featured: [
        {
          name: "Web Développement ",
          lin: "web",
          href: "/offre/web",
          imageSrc: "./images/pub/site.jpg",
          imageAlt:
            "Hats and sweaters on wood shelves next to various colors of t-shirts on hangers.",
        },
        {
          name: "AudioVisuel",
          lin: "audio",
          href: "./offre/audio",
          imageSrc: "./images/pub/vid.jpg",
          imageAlt: "Model wearing light heather gray t-shirt.",
        },
        {
          name: "Impression",
          lin: "print",
          href: "/offre/print",
          imageSrc: "./images/pub/print.jpg",
          imageAlt:
            "Grey 6-panel baseball hat with black brim, black mountain graphic on front, and light heather gray body.",
        },
      ],
    },
  ],
  pages: [{ name: "Entreprise", href: "/company" }],
};
const metrics = [
  {
    id: 1,
    stat: 150,
    emphasis: "Employées",
    rest: "Ce sont tous des professionnels expérimentés.",
    compl: "+",
  },
  {
    id: 2,
    stat: 1,
    emphasis: "Wilaya De Sétif",
    rest: "Nous cherchons à élargir",
    compl: "+",
  },

  {
    id: 3,
    stat: 98,
    emphasis: "Satisfaction du client",
    rest: "Nous nous efforçons d'en faire plus",
    compl: "+",
    compl: "+",
    pour: "%",
  },
  {
    id: 4,
    stat: 120,
    emphasis: "Problèmes résolus",
    rest: "Et le suivant est meilleur.",
    compl: "+",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HomeScreen = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Utilisateur connecté, récupérons les données de Firestore en temps réel
        const userRef = collection(db, "users");
        const q = query(userRef, where("userId", "==", user.uid));

        const unsubscribeSnapshot = onSnapshot(q, (snapshot) => {
          snapshot.forEach((doc) => {
            // Nous avons trouvé l'utilisateur dans la collection
            const userData = doc.data();
            setUser(userData); // Mise à jour de l'état avec toutes les données
          });
        });

        // Vous pouvez également vous désabonner lorsque le composant est démonté
        return () => unsubscribeSnapshot();
      } else {
        // L'utilisateur n'est pas connecté
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate("/login");
    });
  };
  const [userCart, setUserCart] = useState([]);
  const [products, setUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("Utilisateur connecté avec l'ID :", user.uid);
        setUserId(user.uid);
        fetchUserCart(user.uid);
      } else {
        console.log("Aucun utilisateur connecté.");
        setUserId(null);
        setUserCart([]);
      }
    });
    return unsubscribe;
  }, []);

  const fetchUserCart = async (userId) => {
    try {
      console.log("Fetching user cart for userId:", userId);
      const cartQuery = query(
        collection(db, "carts"),
        where("userId", "==", userId)
      );
      const unsubscribe = onSnapshot(cartQuery, (querySnapshot) => {
        const userCartData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserCart(userCartData);
      });
      return unsubscribe; // Assurez-vous de retourner la fonction de désinscription
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du panier de l'utilisateur :",
        error
      );
    }
  };
  const [animatedStats, setAnimatedStats] = useState([]);
  const [inViewRef, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    // Initialize animated stats when metrics change
    const initialAnimatedStats = metrics.map((metric) => metric.stat);
    setAnimatedStats(initialAnimatedStats);
  }, [metrics]);

  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  const ref2 = useRef();
  const isVisible2 = useIsVisible(ref2);

  const ref3 = useRef();
  const isVisible3 = useIsVisible(ref3);

  const ref4 = useRef();
  const isVisible4 = useIsVisible(ref4);

  const ref5 = useRef();
  const isVisible5 = useIsVisible(ref5);

  const ref6 = useRef();
  const isVisible6 = useIsVisible(ref6);

  const ref7 = useRef();
  const isVisible7 = useIsVisible(ref7);

  const ref8 = useRef();
  const isVisible8 = useIsVisible(ref8);

  const ref9 = useRef();
  const isVisible9 = useIsVisible(ref9);

  const ref10 = useRef();
  const isVisible10 = useIsVisible(ref10);

  const ref11 = useRef();
  const isVisible11 = useIsVisible(ref11);

  const ref12 = useRef();
  const isVisible12 = useIsVisible(ref12);

  const ref13 = useRef();
  const isVisible13 = useIsVisible(ref13);

  const ref14 = useRef();
  const isVisible14 = useIsVisible(ref14);

  const ref15 = useRef();
  const isVisible15 = useIsVisible(ref15);

  const ref16 = useRef();
  const isVisible16 = useIsVisible(ref16);

  const ref17 = useRef();
  const isVisible17 = useIsVisible(ref17);

  const ref18 = useRef();
  const isVisible18 = useIsVisible(ref18);

  const ref19 = useRef();
  const isVisible19 = useIsVisible(ref19);

  const ref20 = useRef();
  const isVisible20 = useIsVisible(ref20);

  const ref21 = useRef();
  const isVisible21 = useIsVisible(ref21);

  const ref22 = useRef();
  const isVisible22 = useIsVisible(ref22);

  const ref23 = useRef();
  const isVisible23 = useIsVisible(ref23);

  const ref24 = useRef();
  const isVisible24 = useIsVisible(ref24);

  const ref25 = useRef();
  const isVisible25 = useIsVisible(ref25);

  const ref26 = useRef();
  const isVisible26 = useIsVisible(ref26);

  const ref27 = useRef();
  const isVisible27 = useIsVisible(ref27);

  const ref28 = useRef();
  const isVisible28 = useIsVisible(ref28);

  const ref29 = useRef();
  const isVisible29 = useIsVisible(ref29);

  const ref30 = useRef();
  const isVisible30 = useIsVisible(ref30);

  const ref31 = useRef();
  const isVisible31 = useIsVisible(ref31);

  const ref32 = useRef();
  const isVisible32 = useIsVisible(ref32);

  const ref33 = useRef();
  const isVisible33 = useIsVisible(ref33);

  const ref34 = useRef();
  const isVisible34 = useIsVisible(ref34);

  const ref35 = useRef();
  const isVisible35 = useIsVisible(ref35);

  const ref36 = useRef();
  const isVisible36 = useIsVisible(ref36);

  const ref37 = useRef();
  const isVisible37 = useIsVisible(ref37);

  const ref38 = useRef();
  const isVisible38 = useIsVisible(ref38);

  const ref39 = useRef();
  const isVisible39 = useIsVisible(ref39);

  const ref40 = useRef();
  const isVisible40 = useIsVisible(ref40);

  const ref41 = useRef();
  const isVisible41 = useIsVisible(ref41);

  const ref42 = useRef();
  const isVisible42 = useIsVisible(ref42);

  const ref43 = useRef();
  const isVisible43 = useIsVisible(ref43);

  const ref44 = useRef();
  const isVisible44 = useIsVisible(ref44);

  const ref45 = useRef();
  const isVisible45 = useIsVisible(ref45);

  const ref46 = useRef();
  const isVisible46 = useIsVisible(ref46);

  const ref47 = useRef();
  const isVisible47 = useIsVisible(ref47);

  const ref48 = useRef();
  const isVisible48 = useIsVisible(ref48);

  const ref49 = useRef();
  const isVisible49 = useIsVisible(ref49);

  const ref50 = useRef();
  const isVisible50 = useIsVisible(ref50);

  const ref51 = useRef();
  const isVisible51 = useIsVisible(ref51);

  const handleClick = () => {
    const paragraph = document.getElementById("paragraphe-cible");
    if (paragraph) {
      paragraph.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="bg-white">
      <div>
        <Helmet>
          <title>Page D'Accueil</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as="div" className="mt-2">
                  <div className="border-b border-gray-200">
                    <Tab.List className="-mb-px flex space-x-8 px-4">
                      {navigation.categories.map((category) => (
                        <Tab
                          key={category.nom}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? "text-indigo-600 border-indigo-600"
                                : "text-gray-900 border-transparent",
                              "flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium"
                            )
                          }
                        >
                          {category.name}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>

                  <Tab.Panels as={Fragment}>
                    {navigation.categories.map((category) => (
                      <Tab.Panel
                        key={category.name}
                        className="space-y-12 px-4 py-6"
                      >
                        <div className="grid grid-cols-2 gap-x-4 gap-y-10">
                          {category.featured.map((item) => (
                            <div key={item.name} className="group relative">
                              <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-md bg-gray-100 group-hover:opacity-75">
                                <img
                                  src={item.imageSrc}
                                  alt={item.imageAlt}
                                  className="object-cover object-center"
                                />
                              </div>
                              <a
                                href={`/offre/${item.lin}`}
                                className="mt-6 block text-sm font-medium text-gray-900"
                              >
                                <span
                                  className="absolute inset-0 z-10"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                              <p
                                aria-hidden="true"
                                className="mt-1 text-sm text-gray-500"
                              ></p>
                            </div>
                          ))}
                        </div>
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>

                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        href={page.href}
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>
                {!user && (
                  <>
                    <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                      <div className="flow-root">
                        <a
                          href="/signin"
                          className="-m-2 block p-2 font-medium text-gray-900"
                        >
                          Créer un compte
                        </a>
                      </div>
                      <div className="flow-root">
                        <a
                          href="/login"
                          className="-m-2 block p-2 font-medium text-gray-900"
                        >
                          Log in
                        </a>
                      </div>
                    </div>
                  </>
                )}

                {user && (
                  <>
                    <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                      <div className="flow-root">
                        <a
                          href="/signin"
                          className="-m-2 block p-2 font-medium text-gray-900"
                        >
                          {user.fullName}
                        </a>
                      </div>
                      <div className="flow-root">
                        <div
                          onClick={() => {
                            handleLogout();
                          }}
                          className="-m-2 block p-2 font-medium text-gray-900"
                        >
                          Log out
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  {/* Currency selector */}
                  <form>
                    <div className="inline-block">
                      <label htmlFor="mobile-currency" className="sr-only">
                        Currency
                      </label>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Hero section */}
      <div className="relative bg-gray-900">
        {/* Decorative image and overlay */}
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <img
            src="./images/fd.jpg"
            alt="fd"
            className="h-full w-full  object-cover object-center"
          />
        </div>

        <div
          aria-hidden="true"
          className="absolute inset-0  bg-gradient-to-l from-zinc-600 to-slate-800 mix-blend-multiply    "
        />

        {/* Navigation */}
        <header className="relative z-10">
          <nav aria-label="Top">
            {/* Top navigation */}
            <div className="bg-gray-900">
              <div className="mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                {/* Currency selector */}

                <div>
                  <a
                    href="/inscrption"
                    className="flex items-center rounded-md border-transparent bg-gray-900 bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-white focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-100"
                  >
                    Employée ?
                  </a>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-300"
                      aria-hidden="true"
                    />
                  </div>
                </div>

                {!user && (
                  <>
                    <div className="flex items-center space-x-6">
                      <a
                        href="/login"
                        className="text-sm font-medium text-white hover:text-gray-100"
                      >
                        Log in
                      </a>

                      <a
                        href="/signin"
                        className="text-sm font-medium text-white hover:text-gray-100"
                      >
                        Créer un compte
                      </a>
                    </div>
                  </>
                )}

                {user && (
                  <>
                    <div className="flex items-center space-x-6">
                      <a
                        href="/signin"
                        className="text-sm font-medium text-white hover:text-gray-100"
                      >
                        {user.fullName}
                      </a>

                      <a
                        onClick={() => {
                          handleLogout();
                        }}
                        className="text-sm font-medium text-white hover:text-gray-100"
                      >
                        Log Out
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Secondary navigation */}
            <div className="bg-white bg-opacity-10 backdrop-blur-md backdrop-filter">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div>
                  <div className="flex h-16 items-center justify-between">
                    <div className="hidden lg:flex lg:flex-1 lg:items-center animate-pulse   animate-infinite">
                      <a href="/">
                        <span className="sr-only">Morserv</span>
                        <img
                          className="h-14 w-auto"
                          src="./images/logo2.png"
                          alt=""
                        />
                      </a>
                    </div>

                    <div className="hidden h-full lg:flex">
                      {/* Flyout menus */}
                      <Popover.Group className="inset-x-0 bottom-0 px-4">
                        <div className="flex h-full justify-center space-x-8">
                          {navigation.categories.map((category) => (
                            <Popover key={category.name} className="flex">
                              {({ open }) => (
                                <>
                                  <div className="relative flex">
                                    <Popover.Button className="relative z-10 flex items-center justify-center text-sm font-medium text-white transition-colors duration-200 ease-out">
                                      {category.name}
                                      <span
                                        className={classNames(
                                          open ? "bg-white" : "",
                                          "absolute inset-x-0 -bottom-px h-0.5 transition duration-200 ease-out"
                                        )}
                                        aria-hidden="true"
                                      />
                                    </Popover.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Popover.Panel className="absolute inset-x-0 top-full text-sm text-gray-500">
                                      {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                      <div
                                        className="absolute inset-0 top-1/2 bg-white shadow"
                                        aria-hidden="true"
                                      />

                                      <div className="relative bg-white">
                                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                          <div className="grid grid-cols-4 gap-y-10 gap-x-8 py-16">
                                            {category.featured.map((item) => (
                                              <div
                                                key={item.name}
                                                className="group relative"
                                              >
                                                <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-md bg-gray-100 group-hover:opacity-75">
                                                  <img
                                                    src={item.imageSrc}
                                                    alt={item.imageAlt}
                                                    className="object-cover object-center"
                                                  />
                                                </div>
                                                <a
                                                  href={`/offre/${item.lin}`}
                                                  className="mt-4 block font-medium text-gray-900"
                                                >
                                                  <span
                                                    className="absolute inset-0 z-10"
                                                    aria-hidden="true"
                                                  />
                                                  {item.name}
                                                </a>
                                                <p
                                                  aria-hidden="true"
                                                  className="mt-1"
                                                ></p>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </Popover.Panel>
                                  </Transition>
                                </>
                              )}
                            </Popover>
                          ))}

                          {navigation.pages.map((page) => (
                            <a
                              key={page.name}
                              href={page.href}
                              className="flex items-center text-sm font-me dium text-white"
                            >
                              {page.name}
                            </a>
                          ))}
                        </div>
                      </Popover.Group>
                    </div>

                    {/* Mobile menu and search (lg-) */}
                    <div className="flex flex-1 items-center lg:hidden">
                      <button
                        type="button"
                        className="-ml-2 p-2 text-white"
                        onClick={() => setMobileMenuOpen(true)}
                      >
                        <span className="sr-only">Open menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      </button>

                      {/* Search */}
                      <a href="/search" className="ml-2 p-2 text-white">
                        <span className="sr-only">Recherche</span>
                        <MagnifyingGlassIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </a>
                    </div>

                    {/* Logo (lg-) */}
                    <a
                      href="/"
                      className="lg:hidden animate-pulse animate-infinite"
                    >
                      <span className="sr-only">Morserv</span>
                      <img
                        src="./images/logo.png"
                        alt="morserv"
                        className="h-12 w-auto"
                      />
                    </a>

                    <div className="flex flex-1 items-center justify-end">
                      <a
                        href="/search"
                        className="hidden text-sm font-medium text-white lg:block"
                      >
                        Recherche
                      </a>

                      <div className="flex items-center lg:ml-8">
                        {/* Help */}
                        <a href="/contact" className="p-2 text-white lg:hidden">
                          <span className="sr-only">Contact</span>
                          <QuestionMarkCircleIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </a>
                        <a
                          href="/contact"
                          className="hidden text-sm font-medium text-white lg:block"
                        >
                          Contact
                        </a>

                        {/* Cart */}
                        <div className="ml-4 flow-root lg:ml-8">
                          <a
                            href="/cart"
                            className="group -m-2 flex items-center p-2"
                          >
                            <ShoppingBagIcon
                              className="h-6 w-6 flex-shrink-0 text-white"
                              aria-hidden="true"
                            />
                            <span className="ml-2 text-sm font-medium text-white">
                              {userCart.map((cart) => (
                                <div>{cart.items.length}</div>
                              ))}
                            </span>
                            <span className="sr-only">
                              Articles dans le panier, voir le panier
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>

        <div className="relative mx-auto flex max-w-3xl flex-col items-center py-32 px-6 text-center sm:py-64 lg:px-0  ">
          <h1
            ref={ref1}
            className={`text-4xl font-bold items-left tracking-tight text-white lg:text-6xl hover:animate-wiggle hover:animate-infinite  ${
              isVisible1
                ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                : "opacity-0 invisible   "
            }`}
          >
            C'est la solution
          </h1>
          <p
            ref={ref2}
            className={`mt-4 text-xl text-white ${
              isVisible2
                ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                : "opacity-0 invisible   "
            }`}
          >
            Bien plus qu'une entreprise, une solution en action.
          </p>
          <a
            ref={ref13}
            className={`mt-8 items-center inline-block rounded-md border border-transparent bg-white py-3 px-8 text-base font-medium text-gray-900 hover:bg-gray-100  ${
              isVisible13
                ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                : "opacity-0 invisible   "
            }`}
            onClick={handleClick}
          >
            Commander Un Employé !
          </a>
        </div>
      </div>

      <main>
        {/* Category section */}
        <section
          aria-labelledby="category-heading"
          className="pt-24 sm:pt-32 xl:mx-auto xl:max-w-7xl xl:px-8"
          id="paragraphe-cible"
        >
          <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0">
            <h2
              ref={ref14}
              className={`text-2xl font-bold tracking-tight text-gray-900 ${
                isVisible14
                  ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                  : "opacity-0 invisible   "
              }`}
              id="category-heading"
            >
              Nos Services
            </h2>
            <a
              ref={ref15}
              className={`hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block ${
                isVisible15
                  ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                  : "opacity-0 invisible   "
              }`}
              href="/services"
            >
              Tous Services
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>

          {/* Card Service */}
          <div className="mt-4 flow-root">
            <div className="-my-2">
              <div className="relative box-content h-80 overflow-x-auto py-2 xl:overflow-visible">
                <div className="min-w-screen-xl absolute flex space-x-8 px-4 sm:px-6 lg:px-8 xl:relative xl:grid xl:grid-cols-5 xl:gap-x-8 xl:space-x-0 xl:px-0">
                  <a
                    ref={ref3}
                    className={`relative flex h-80 w-56 flex-col  hover:animate-fade overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto group  ${
                      isVisible3
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                    href="/offre/plomberie"
                  >
                    {/* Normal state image */}
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 group-hover:hidden"
                    >
                      <img
                        src="./images/serv/plomb.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </span>

                    {/* Hover state image */}
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 hidden group-hover:block"
                    >
                      <img
                        src="./images/hover/plomb.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </span>
                    <span
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                    />
                    <span
                      ref={ref4}
                      className={`relative mt-auto text-center text-xl font-bold text-white  ${
                        isVisible4
                          ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                          : "opacity-0 invisible   "
                      }`}
                    >
                      Plomberie
                    </span>
                  </a>

                  <a
                    ref={ref5}
                    className={`relative flex h-80 w-56 flex-col  hover:animate-fade  overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto group ${
                      isVisible5
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                    href="/offre/maconnerie"
                  >
                    {/* Normal state image */}
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 group-hover:hidden"
                    >
                      <img
                        src="./images/serv/macon.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </span>

                    {/* Hover state image */}
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 hidden group-hover:block"
                    >
                      <img
                        src="./images/hover/macon.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </span>
                    <span
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                    />
                    <span
                      ref={ref6}
                      className={`relative mt-auto text-center text-xl font-bold text-white  ${
                        isVisible6
                          ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                          : "opacity-0 invisible   "
                      }`}
                    >
                      Maçonnerie
                    </span>
                  </a>

                  <a
                    ref={ref7}
                    className={`relative flex h-80 w-56 flex-col hover:animate-fade overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto group  ${
                      isVisible7
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                    href="/offre/placo"
                  >
                    {/* Normal state image */}
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 group-hover:hidden"
                    >
                      <img
                        src="./images/serv/placo.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </span>

                    {/* Hover state image */}
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 hidden group-hover:block"
                    >
                      <img
                        src="./images/hover/placo.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </span>
                    <span
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                    />
                    <span
                      ref={ref8}
                      className={`relative mt-auto text-center text-xl font-bold text-white  ${
                        isVisible8
                          ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                          : "opacity-0 invisible   "
                      }`}
                    >
                      Placo
                    </span>
                  </a>

                  <a
                    ref={ref9}
                    className={`relative flex h-80 w-56 flex-col overflow-hidden  hover:animate-fade rounded-lg p-6 hover:opacity-75 xl:w-auto group  ${
                      isVisible9
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                    href="/offre/electric"
                  >
                    {/* Normal state image */}
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 group-hover:hidden"
                    >
                      <img
                        src="./images/serv/elec.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </span>

                    {/* Hover state image */}
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 hidden group-hover:block"
                    >
                      <img
                        src="./images/hover/elec.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </span>
                    <span
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                    />
                    <span
                      ref={ref10}
                      className={`relative mt-auto text-center text-xl font-bold text-white  ${
                        isVisible10
                          ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                          : "opacity-0 invisible   "
                      }`}
                    >
                      Electricite
                    </span>
                  </a>

                  <a
                    ref={ref11}
                    className={`relative flex h-80 w-56 flex-col  hover:animate-fade overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto group  ${
                      isVisible11
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                    href="/offre/froid"
                  >
                    {/* Normal state image */}
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 group-hover:hidden"
                    >
                      <img
                        src="./images/serv/froi.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </span>

                    {/* Hover state image */}
                    <span
                      aria-hidden="true"
                      className="absolute inset-0 hidden group-hover:block"
                    >
                      <img
                        src="./images/hover/froi.jpg"
                        alt=""
                        className="h-full w-full object-cover object-center"
                      />
                    </span>
                    <span
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                    />
                    <span
                      ref={ref12}
                      className={`relative mt-auto text-center text-xl font-bold text-white  ${
                        isVisible12
                          ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                          : "opacity-0 invisible   "
                      }`}
                    >
                      Froid
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 px-4 sm:hidden">
            <a
              ref={ref46}
              className={` block text-sm font-semibold text-indigo-600 hover:text-indigo-500  ${
                isVisible46
                  ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                  : "opacity-0 invisible   "
              }`}
              href="/services"
            >
              Tous Services
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </section>

        {/* Featured section */}
        <section
          aria-labelledby="social-impact-heading"
          className="mx-auto   max-w-7xl px-4 pt-24 sm:px-6 sm:pt-32 lg:px-8  "
        >
          <div className="relative rounded-lg">
            <div
              aria-hidden="true"
              className="absolute rounded-lg  inset-0 overflow-hidden "
            >
              <img
                src="./images/serv.jpg"
                alt="serv"
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div
              aria-hidden="true"
              className="absolute  rounded-lg inset-0 bg-gradient-to-l from-zinc-600 to-slate-800 mix-blend-multiply    "
            />

            <div className="relative rounded-lg    py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
              <div className="relative mx-auto flex max-w-3xl flex-col items-center text-center">
                <h2
                  id="social-impact-heading"
                  className="text-3xl font-bold tracking-tight text-white sm:text-4xl"
                >
                  <span
                    ref={ref17}
                    className={`block sm:inline  ${
                      isVisible17
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    A propos De Nos Services
                  </span>
                </h2>
                <p
                  ref={ref18}
                  className={`mt-3 text-xl text-white  ${
                    isVisible18
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Nous sommes ravis de vous accueillir aujourd'hui pour vous
                  présenter notre entreprise, Tous Services de Bâtiment. Nous
                  sommes une entreprise dédiée à la construction, à la
                  rénovation et à la maintenance des bâtiments.
                </p>
                <a
                  ref={ref19}
                  className={`mt-8 block w-full rounded-md border border-transparent bg-white py-3 px-8 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto ${
                    isVisible19
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                  href="/company"
                >
                  Savoir Plus
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Category section */}

        <section
          aria-labelledby="collection-heading"
          className="mx-auto max-w-xl px-4 pt-24 sm:px-6 sm:pt-32 lg:max-w-7xl lg:px-8"
        >
          <h2
            ref={ref20}
            className={`text-2xl font-bold tracking-tight text-gray-900 ${
              isVisible20
                ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                : "opacity-0 invisible   "
            }`}
            id="collection-heading"
          >
            MorPub
          </h2>
          <p
            ref={ref21}
            className={`mt-4 text-base text-gray-500 ${
              isVisible21
                ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                : "opacity-0 invisible   "
            }`}
          >
            Nous sommes honorés de vous présenter aujourd'hui notre entreprise,
            Tous Services de Publicité.
          </p>

          <div className="mt-10 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
            <a
              ref={ref22}
              className={`group block ${
                isVisible22
                  ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                  : "opacity-0 invisible   "
              }`}
              href="/offre/web"
            >
              <div
                aria-hidden="true"
                className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg   lg:aspect-w-5 lg:aspect-h-6 group-hover:hidden"
              >
                <img
                  src="./images/pub/site.jpg"
                  alt="{collection.imageAlt}"
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div
                aria-hidden="true"
                className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg group- 5 lg:aspect-w-5 lg:aspect-h-6 hidden group-hover:block"
              >
                <img
                  src="./images/hover/logodev.png"
                  alt="{collection.imageHvr}"
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div
                ref={ref25}
                className={`  ${
                  isVisible25
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                <h3 className="mt-4 text-base font-semibold text-gray-900">
                  Web Développement
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  Votre succès en ligne, notre priorité : Services complets de
                  développement web
                </p>
              </div>
            </a>

            <a
              ref={ref23}
              className={`group block ${
                isVisible23
                  ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                  : "opacity-0 invisible   "
              }`}
              href="/offre/audio"
            >
              <div
                aria-hidden="true"
                className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg   lg:aspect-w-5 lg:aspect-h-6 group-hover:hidden"
              >
                <img
                  src="./images/pub/vid.jpg"
                  alt="{collection.imageAlt}"
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div
                aria-hidden="true"
                className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg group- 5 lg:aspect-w-5 lg:aspect-h-6 hidden group-hover:block"
              >
                <img
                  src="./images/hover/logoaud.png" // Use
                  alt="{collection.imageHvr}"
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div
                ref={ref26}
                className={`  ${
                  isVisible26
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                <h3 className="mt-4 text-base font-semibold text-gray-900">
                  AudioVisuel
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  Inspirez, émotionnez, communiquez : Votre partenaire
                  audiovisuel de confiance
                </p>
              </div>
            </a>

            <a
              ref={ref24}
              className={`group block  ${
                isVisible24
                  ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                  : "opacity-0 invisible   "
              }`}
              href="/offre/print"
            >
              <div
                aria-hidden="true"
                className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg   lg:aspect-w-5 lg:aspect-h-6 group-hover:hidden"
              >
                <img
                  src="./images/pub/print.jpg"
                  alt="{collection.imageAlt}"
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div
                aria-hidden="true"
                className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg group- 5 lg:aspect-w-5 lg:aspect-h-6 hidden group-hover:block"
              >
                <img
                  src="./images/hover/logoprint.png"
                  alt="{collection.imageHvr}"
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div
                ref={ref27}
                className={`  ${
                  isVisible27
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                <h3 className="mt-4 text-base font-semibold text-gray-900">
                  Impression & Publicité
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  Le pouvoir de la communication visuelle : Services
                  d'impression et de publicité
                </p>
              </div>
            </a>
          </div>
        </section>

        <div className="relative mt-16 bg-gray-900">
          <div className=" absolute inset-x-0 bottom-0 h-80 xl:top-0 xl:h-full">
            <div className="h-full w-full xl:grid xl:grid-cols-2">
              <div className="h-full xl:relative xl:col-start-2">
                <img
                  className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                  src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                  alt="People working on laptops"
                />
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                />
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-4xl px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8">
            <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
              <h2 className="text-base font-semibold">
                <span
                  ref={ref40}
                  className={`bg-gradient-to-r from-purple-300 to-indigo-300 bg-clip-text text-transparent ${
                    isVisible40
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Elle est la solution
                </span>
              </h2>
              <p
                ref={ref41}
                className={`mt-3 text-3xl font-bold tracking-tight text-white ${
                  isVisible41
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                Moreserv partage avec vous quelques-unes de ses statistiques,
                précisant que son activité a débuté le 1er mars 2023
              </p>
              <p
                ref={ref42}
                className={`mt-5 text-lg text-gray-300 ${
                  isVisible42
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                Nous vous présentons ces modestes statistiques que nous
                cherchons à développer en termes de densité et d'efficacité des
                travailleurs, ainsi que de temps de réponse. Notre devise est «
                Nous sommes la solution » et la solution est de vous servir la
                meilleure solution.
              </p>
              <div
                ref={ref43}
                className={`mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2 ${
                  isVisible43
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                {metrics.map((metric, index) => (
                  <p key={metric.id}>
                    <span className="block text-2xl font-bold text-white">
                      <div className="countdown font-mono text-6xl">
                        <span ref={inViewRef}>
                          {" "}
                          {metric.compl}
                          {inView ? (
                            <CountUp end={animatedStats[index]} duration={2} />
                          ) : (
                            "0"
                          )}
                          {metric.pour}
                        </span>
                      </div>
                    </span>
                    <span className="mt-1 block text-base text-gray-300">
                      <span className="font-medium text-white">
                        {metric.emphasis}
                      </span>{" "}
                      {metric.rest}
                    </span>
                  </p>
                ))}
              </div>
              <div className="grid grid-cols-2 gap-4"></div>
            </div>
          </div>
        </div>

        <div className="relative bg-white py-16 sm:py-24 lg:py-12">
          <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
            <h2
              ref={ref44}
              className={`text-lg font-semibold text-cyan-600 ${
                isVisible44
                  ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                  : "opacity-0 invisible   "
              }`}
            >
              Elle est la solution
            </h2>
            <p
              ref={ref45}
              className={`mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ${
                isVisible45
                  ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                  : "opacity-0 invisible   "
              }`}
            >
              Pourquoi commander au Morserv ou Morpub
            </p>
            <p className="mx-auto mt-8 max-w-prose text-xl text-gray-500"></p>
            <div className="mt-16">
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {/* tap 1 */}
                <div
                  ref={ref28}
                  className={`pt-6  ${
                    isVisible28
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-b from-gray-900 to-sky-900 p-3 shadow-lg">
                          <PhoneIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div
                        ref={ref29}
                        className={`  ${
                          isVisible29
                            ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                            : "opacity-0 invisible   "
                        }`}
                      >
                        <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                          Nos experts à votre service assistance 24h/24 et 7j/7
                        </h3>
                        <p className="mt-5 text-base text-gray-500">
                          Des Employés haut de gamme rigoureusement
                          sélectionnées.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* tap */}
                <div
                  ref={ref30}
                  className={`pt-6  ${
                    isVisible30
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-b from-gray-900 to-sky-900 p-3 shadow-lg">
                          <RocketLaunchIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div
                        ref={ref31}
                        className={`  ${
                          isVisible31
                            ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                            : "opacity-0 invisible   "
                        }`}
                      >
                        <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                          Nous fournissons une interaction dans les plus brefs
                          délais
                        </h3>
                        <p className="mt-5 text-base text-gray-500">
                          Faites une demande et en répondons sous 2h.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* tap */}
                <div
                  ref={ref32}
                  className={`pt-6  ${
                    isVisible32
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-b from-gray-900 to-sky-900 p-3 shadow-lg">
                          <LockClosedIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div
                        ref={ref33}
                        className={`  ${
                          isVisible33
                            ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                            : "opacity-0 invisible   "
                        }`}
                      >
                        <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                          Atteindre la sécurité
                        </h3>
                        <p className="mt-5 text-base text-gray-500">
                          Un Suivi des prestations en temps réel grâce à notre
                          site web
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* tap */}
                <div
                  ref={ref34}
                  className={`pt-6  ${
                    isVisible34
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-b from-gray-900 to-sky-900 p-3 shadow-lg">
                          <GiftIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div
                        ref={ref35}
                        className={`  ${
                          isVisible35
                            ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                            : "opacity-0 invisible   "
                        }`}
                      >
                        <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                          La gratuité de l'accès à la plateforme
                        </h3>
                        <p className="mt-5 text-base text-gray-500">
                          Sur notre plateforme, vous pouvez demander un service,
                          à domicile et partout ailleurs .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* tap */}
                <div
                  ref={ref36}
                  className={`pt-6  ${
                    isVisible36
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-b from-gray-900 to-sky-900 p-3 shadow-lg">
                          <CurrencyDollarIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div
                        ref={ref37}
                        className={`  ${
                          isVisible37
                            ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                            : "opacity-0 invisible   "
                        }`}
                      >
                        <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                          La politique des prix et les promotions
                        </h3>
                        <p className="mt-5 text-base text-gray-500">
                          Dans l'intention de satisfaire à la fois nos clients
                          et nos employées
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* tap */}
                <div
                  ref={ref38}
                  className={`pt-6  ${
                    isVisible38
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-b from-gray-900 to-sky-900 p-3 shadow-lg">
                          <ShieldCheckIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div
                        ref={ref39}
                        className={`  ${
                          isVisible39
                            ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                            : "opacity-0 invisible   "
                        }`}
                      >
                        <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                          MorServ: un réseau Service hors du commun
                        </h3>
                        <p className="mt-5 text-base text-gray-500">
                          Soyez Confortable et servir Votre Maison
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer2 />
    </div>
  );
};
export default HomeScreen;
