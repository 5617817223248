import { useRef, useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { db } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { addDoc, collection } from "@firebase/firestore";
import NavBar from "../Components/Navbar";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const SigninScreen = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const handleSignin = async (e) => {
    e.preventDefault();

    const fullName = e.target.elements.fullName.value;
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;

    if (!fullName || !email || !password) {
      setErrorMsg("Veuillez remplir tous les champs requis.");
      return;
    }

    const auth = getAuth();

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Vous pouvez ajouter des données à la collection Firestore ici
      const formData = {
        fullName: fullName,
        email: email,
        userId: userCredential.user.uid, // Vous pouvez ajouter l'ID de l'utilisateur ici
      };

      const docRef = await addDoc(collection(db, "users"), formData);
      e.target.elements.fullName.value = "";
      e.target.elements.email.value = "";
      e.target.elements.password.value = "";
      navigate("/login");
      setSuccessMsg(
        "Utilisateur créé avec succès. Document ajouté avec l'ID : " + docRef.id
      );
      toast.success("Document écrit avec succès avec l'ID : " + docRef.id);
    } catch (error) {
      setErrorMsg(
        "Erreur lors de la création de l'utilisateur : " + error.message
      );
      toast.error("Erreur lors de l'écriture du document :", error);
    }
  };
  return (
    <>
      <div>
        <div>
          <Helmet>
            <title>Sign in</title>
            <meta name="description" content="Ma description" />
            {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
          </Helmet>
        </div>
        <NavBar />
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in{" "}
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              {successMsg && (
                <p
                  class="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50   dark:text-green-400"
                  role="alert"
                >
                  {successMsg}
                </p>
              )}

              <form ref={formRef} className="space-y-6" onSubmit={handleSignin}>
                <div>
                  <label
                    htmlFor="fullName"
                    className="block flex text-start  text-sm font-medium text-gray-700"
                  >
                    Nom Complet
                  </label>
                  <div className="mt-1">
                    <input
                      id="fullName"
                      name="fullName"
                      type="fullName"
                      autoComplete="fullName"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block flex text-start  text-sm font-medium text-gray-700"
                  >
                    Adresse e-mail
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm text-start font-medium text-gray-700"
                  >
                    Mot de passe
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="mt-6 flex w-full justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                  >
                    Sign in
                  </button>
                </div>
              </form>
              {errorMsg && (
                <p
                  class="p-4 mt-5 mb-4 text-sm text-red-800 rounded-lg bg-red-50   dark:text-red-400"
                  role="alert"
                >
                  {errorMsg}
                </p>
              )}
              <div className="mt-6">
                <div className="flex items-center justify-between">
                  <div className="text-sm">
                    Vous avez déjà un compte ?
                    <a
                      href="/login"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {" "}
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SigninScreen;
