import { useEffect, useRef, useState } from "react";
import { collection, onSnapshot, query, where } from "@firebase/firestore";
import { db } from "../config/firebase";
import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import NavBar2 from "../Components/Navbar2";
import { Helmet } from "react-helmet";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Description() {
  const [formulaire, setForms] = useState([]);
  const formu = collection(db, "descrption");
  const parms = useParams();
  const { code } = parms;
  useEffect(() => {
    const queryMessages = query(formu, where("code", "==", code));
    const unsubscribe = onSnapshot(queryMessages, (snapshot) => {
      let formulaire = [];
      snapshot.forEach((doc) => {
        formulaire.push({ ...doc.data(), id: doc.id });
      });
      setForms(formulaire);
    });

    return () => {
      console.log("Unsubscribed");
      unsubscribe();
    };
  }, [code]);

  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <div className="bg-white">
      <div>
        <Helmet>
          <title> {code}</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <header className="relative bg-sky-800 pb-24 sm:pb-32">
        <NavBar2 />

        {formulaire.map((product) => (
          <div>
            <div className="absolute inset-0">
              <img
                className="h-full w-full object-cover"
                src={product.imageUrl}
                alt=""
              />
              <div
                className="absolute inset-0 bg-gradient-to-l from-sky-500 to-slate-700 mix-blend-multiply"
                aria-hidden="true"
              />
            </div>

            <div className="relative mx-auto mt-24 max-w-md px-6 sm:mt-32 sm:max-w-3xl lg:max-w-7xl lg:px-8">
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">
                {product.titre}
              </h1>
            </div>
          </div>
        ))}
      </header>

      <main>
        <div
          ref={ref1}
          className={` ${
            isVisible1
              ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
              : "opacity-0 invisible   "
          }`}
        >
          {formulaire.map((product) => (
            <div className="bg-white">
              <div className="bg-warm-gray-50">
                <div className="flex text-left mx-auto max-w-md py-2 px-6 sm:max-w-3xl sm:py-12 lg:max-w-7xl lg:px-8">
                  {product.descrption}
                </div>

                <section aria-labelledby="details-heading">
                  <h2 id="details-heading" className="sr-only">
                    Additional details
                  </h2>

                  <div className="divide-y divide-gray-200 border-t mx-auto max-w-md py-10 px-6 sm:max-w-3xl  lg:max-w-7xl lg:px-8">
                    <div>
                      <Disclosure as="div" key={product.titre}>
                        {({ open }) => (
                          <>
                            <h3>
                              <Disclosure.Button className="group relative flex w-full items-center justify-between py-2 text-left">
                                <span
                                  className={classNames(
                                    open ? "text-indigo-600" : "text-gray-900",
                                    "text-sm font-medium"
                                  )}
                                >
                                  Voici quelques éléments clés de service{" "}
                                  {product.titre}
                                </span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon
                                      className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusIcon
                                      className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel
                              as="div"
                              className="prose prose-sm pb-6"
                            >
                              <ul role="list">
                                {product.arry.map((product) => (
                                  <li
                                    className="flex text-left mx-auto max-w-md py-2 px-6 sm:max-w-3xl  lg:max-w-7xl lg:px-8"
                                    key={product}
                                  >
                                    {product}
                                  </li>
                                ))}
                              </ul>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          ))}
        </div>
      </main>

      <Footer />
    </div>
  );
}
