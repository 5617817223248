// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAg8h5w-k-22s0UtC10NUtvJWxKG3yQL-U",
  authDomain: "e-commerce-react-morserv.firebaseapp.com",
  projectId: "e-commerce-react-morserv",
  storageBucket: "e-commerce-react-morserv.appspot.com",
  messagingSenderId: "336241499578",
  appId: "1:336241499578:web:7cb3232d24702b1d5cd183",
  measurementId: "G-2D8GYXZXRP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
