import React, { useEffect } from "react";

const RedirectionComponent = () => {
  useEffect(() => {
    // Redirige vers le lien souhaité dès que le composant est monté
    window.location.href =
      "https://firebasestorage.googleapis.com/v0/b/e-commerce-react-morserv.appspot.com/o/app-release%20(3).apk?alt=media&token=88487ad4-b55b-4177-873a-1376110e17cb";
  }, []); // Le tableau vide signifie que cela ne dépend d'aucune dépendance et s'exécute une seule fois après le montage

  // Le composant peut également afficher un message ou un contenu
  return (
    <div>
      <p>Hello Creative world ! </p>
    </div>
  );
};

export default RedirectionComponent;
