import { useEffect, useRef, useState } from "react";
import Footer from "./Components/Footer";
import NavBar2 from "./Components/Navbar2";
import { Helmet } from "react-helmet";

export default function CompanyScreen() {
  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  const ref2 = useRef();
  const isVisible2 = useIsVisible(ref2);

  const ref3 = useRef();
  const isVisible3 = useIsVisible(ref3);

  const ref4 = useRef();
  const isVisible4 = useIsVisible(ref4);

  const ref5 = useRef();
  const isVisible5 = useIsVisible(ref5);

  const ref6 = useRef();
  const isVisible6 = useIsVisible(ref6);

  const ref7 = useRef();
  const isVisible7 = useIsVisible(ref7);

  const ref8 = useRef();
  const isVisible8 = useIsVisible(ref8);

  const ref9 = useRef();
  const isVisible9 = useIsVisible(ref9);

  const ref10 = useRef();
  const isVisible10 = useIsVisible(ref10);

  const ref11 = useRef();
  const isVisible11 = useIsVisible(ref11);

  const ref12 = useRef();
  const isVisible12 = useIsVisible(ref12);

  const ref13 = useRef();
  const isVisible13 = useIsVisible(ref13);

  const ref14 = useRef();
  const isVisible14 = useIsVisible(ref14);

  const ref15 = useRef();
  const isVisible15 = useIsVisible(ref15);

  const ref16 = useRef();
  const isVisible16 = useIsVisible(ref16);

  const ref17 = useRef();
  const isVisible17 = useIsVisible(ref17);

  const ref18 = useRef();
  const isVisible18 = useIsVisible(ref18);

  const ref19 = useRef();
  const isVisible19 = useIsVisible(ref19);

  const ref20 = useRef();
  const isVisible20 = useIsVisible(ref20);

  const ref21 = useRef();
  const isVisible21 = useIsVisible(ref21);

  const ref22 = useRef();
  const isVisible22 = useIsVisible(ref22);

  const ref23 = useRef();
  const isVisible23 = useIsVisible(ref23);

  const ref24 = useRef();
  const isVisible24 = useIsVisible(ref24);

  const ref25 = useRef();
  const isVisible25 = useIsVisible(ref25);

  const ref26 = useRef();
  const isVisible26 = useIsVisible(ref26);

  const ref27 = useRef();
  const isVisible27 = useIsVisible(ref27);

  const ref28 = useRef();
  const isVisible28 = useIsVisible(ref28);

  const ref29 = useRef();
  const isVisible29 = useIsVisible(ref29);

  const ref30 = useRef();
  const isVisible30 = useIsVisible(ref30);

  const ref31 = useRef();
  const isVisible31 = useIsVisible(ref31);

  const ref32 = useRef();
  const isVisible32 = useIsVisible(ref32);

  const ref33 = useRef();
  const isVisible33 = useIsVisible(ref33);
  return (
    <div className="bg-white">
      <div>
        <Helmet>
          <title>Company</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <header className="relative bg-sky-800 pb-24 sm:pb-32">
        <div className="absolute inset-0">
          <img
            className="h-full w-full object-cover"
            src="./images/fd2.jpg"
            alt=""
          />
          <div
            className="absolute inset-0 bg-gradient-to-l from-sky-700 to-gray-600 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>

        <NavBar2 />

        <div className="relative mx-auto mt-24 max-w-md px-6 sm:mt-32 sm:max-w-3xl lg:max-w-7xl lg:px-8">
          <h1
            ref={ref5}
            className={`text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl ${
              isVisible5
                ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                : "opacity-0 invisible   "
            }`}
          >
            Qui Sommes Nous ?
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-cyan-100"></p>
        </div>
      </header>

      <main>
        <section>
          <div className="bg-warm-gray-50">
            <div className="mx-auto max-w-md py-24 px-6 sm:max-w-3xl sm:py-20 lg:max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div
                  ref={ref1}
                  className={` ${
                    isVisible1
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    À Propos De
                  </h2>
                  <p className="mt-4 text-lg text-warm-gray-500">
                    {" "}
                    <a
                      href="#"
                      className="font-medium text-3xl text-cyan-700 hover:text-cyan-600"
                    >
                      Morserv
                    </a>
                  </p>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        <p
                          ref={ref2}
                          className={` ${
                            isVisible2
                              ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                              : "opacity-0 invisible   "
                          }`}
                        >
                          Morserv Fournir Multi Services incarne la quintessence
                          de la polyvalence dans le monde des services
                          professionnels. Avec une expertise diversifiée et un
                          engagement indéfectible envers la satisfaction de la
                          clientèle, Fournir Multi Services a rapidement émergé
                          comme une référence incontournable dans le domaine de
                          la construction et de la maintenance. Cette entreprise
                          dynamique et innovante se distingue par sa capacité à
                          répondre à une vaste gamme de besoins, allant de la
                          plomberie à la maçonnerie, de l'électricité à la
                          peinture, de la pose de placoplâtre à l'installation
                          de systèmes de climatisation, de la logistique de
                          livraison au nettoyage minutieux.
                        </p>
                        <br />
                        <p
                          ref={ref3}
                          className={` ${
                            isVisible3
                              ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                              : "opacity-0 invisible   "
                          }`}
                        >
                          L'excellence est le leitmotiv de Fournir Multi
                          Services, incarnée à travers une équipe de
                          professionnels hautement qualifiés et dévoués, prêts à
                          relever les défis les plus complexes. Qu'il s'agisse
                          de projets de construction résidentielle ou
                          commerciale, de rénovations majeures ou de services de
                          maintenance, Fournir Multi Services se tient à la
                          disposition de ses clients pour garantir un travail de
                          qualité, exécuté dans les délais impartis et conforme
                          aux normes les plus rigoureuses de l'industrie.
                        </p>
                        <br />
                        <p
                          ref={ref6}
                          className={` ${
                            isVisible6
                              ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                              : "opacity-0 invisible   "
                          }`}
                        >
                          La polyvalence est au cœur de l'ADN de l'entreprise,
                          permettant ainsi à ses clients de simplifier leurs
                          projets en un seul interlocuteur de confiance. Avec
                          une gamme complète de services, Fournir Multi Services
                          offre une solution complète, de la conception initiale
                          à la réalisation finale, et au-delà, avec des services
                          d'entretien et de nettoyage pour préserver la beauté
                          et la fonctionnalité de chaque projet.
                        </p>
                        <br />
                        <p
                          ref={ref4}
                          className={` ${
                            isVisible4
                              ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                              : "opacity-0 invisible   "
                          }`}
                        >
                          En somme, Fournir Multi Services incarne l'avenir de
                          la prestation de services, offrant une réponse
                          complète et intégrée à tous les besoins de
                          construction, de maintenance et de rénovation.
                          L'entreprise s'engage à fournir des solutions
                          efficaces, de haute qualité et personnalisées pour
                          répondre aux besoins diversifiés de sa clientèle, et à
                          contribuer ainsi à la création de lieux de vie et de
                          travail exceptionnels.
                        </p>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          aria-labelledby="category-heading"
          className=" xl:mx-auto xl:max-w-7xl xl:px-8"
        >
          <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0">
            <h2
              ref={ref7}
              className={`text-2xl font-bold tracking-tight text-gray-900 ${
                isVisible7
                  ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                  : "opacity-0 invisible   "
              }`}
              id="category-heading"
            >
              Savoir plus sur nos services
            </h2>
          </div>

          <div className="mx-auto max-w-xl px-4 pt-24 sm:px-6 sm:pt-32 lg:max-w-7xl lg:px-8">
            <div className="space-y-12 lg:grid grid-rows-2 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-8 lg:space-y-0">
              <a
                ref={ref8}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible8
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/company/plomberie"
              >
                {" "}
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/plomb.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref9}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible9
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Plomberie
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref10}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible10
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/company/maconnerie"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/macon.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref11}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible11
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Maçonnerie
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref12}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible12
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/company/peinture"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/peintr.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref13}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible13
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Peinture
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref14}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible14
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/company/electric"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/elec.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref15}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible15
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Electricité
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref22}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible22
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/company/froid"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/froid.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref23}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible23
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Froid
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref16}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible16
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/company/placo"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/placo.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref17}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible17
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Placo Platre
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref18}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible18
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/company/nettoyage"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/neto.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref19}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible19
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Nettoyage
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref20}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible20
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/company/livraison"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/livr.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref21}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible21
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Livraison
                </span>
              </a>
            </div>
          </div>
        </section>

        <section>
          <div className="bg-warm-gray-50">
            <div className="mx-auto max-w-md py-24 px-6 sm:max-w-3xl sm:py-20 lg:max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div
                  ref={ref24}
                  className={`  ${
                    isVisible24
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    À Propos De
                  </h2>
                  <p className="mt-4 text-lg text-warm-gray-500">
                    {" "}
                    <a
                      href="#"
                      className="font-medium text-3xl text-cyan-700 hover:text-cyan-600"
                    >
                      MorPub
                    </a>
                  </p>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        <p
                          ref={ref25}
                          className={`  ${
                            isVisible25
                              ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                              : "opacity-0 invisible   "
                          }`}
                        >
                          L'industrie de la publicité et du marketing a
                          considérablement évolué au fil des décennies, passant
                          d'une simple diffusion de messages publicitaires à une
                          vaste palette de services multidimensionnels. Dans ce
                          contexte en constante évolution, l'entreprise MorPub
                          s'est imposée comme un acteur majeur, offrant une
                          gamme complète de services de publicité, allant de
                          l'impression traditionnelle à la pointe de la
                          technologie au développement web, en passant par le
                          marketing digital et la production audiovisuelle.
                        </p>
                        <br />
                        <p
                          ref={ref26}
                          className={`  ${
                            isVisible26
                              ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                              : "opacity-0 invisible   "
                          }`}
                        >
                          Notre entreprise a été fondée sur la conviction que la
                          publicité efficace ne se limite pas à une seule
                          approche, mais plutôt à une synergie entre différents
                          canaux et médias. Ainsi,nous nous sommes engagés à
                          aider nos clients à atteindre leurs objectifs en
                          créant des campagnes publicitaires innovantes et
                          percutantes qui s'adaptent aux besoins spécifiques de
                          chaque entreprise.
                        </p>
                        <br />
                        <p
                          ref={ref27}
                          className={`  ${
                            isVisible27
                              ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                              : "opacity-0 invisible   "
                          }`}
                        >
                          Dans cette introduction, nous allons explorer les
                          diverses facettes de MorPub, mettant en lumière notre
                          expertise dans les domaines de l'impression, de la
                          production audiovisuelle, du marketing digital et du
                          développement web. Nous montrerons comment notre
                          entreprise combine ces compétences pour offrir une
                          solution globale de publicité, adaptée aux défis et
                          aux opportunités du marché contemporain.
                        </p>
                        <br />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          aria-labelledby="category-heading"
          className=" pb-16 xl:mx-auto xl:max-w-7xl xl:px-8"
        >
          <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0">
            <h2
              id="category-heading"
              className="text-2xl font-bold tracking-tight text-gray-900"
            >
              Savoir plus sur nos services de publicité
            </h2>
          </div>

          <div className="mb-16 mx-auto max-w-xl px-4   pt-12 sm:px-6 sm:pt-24 lg:max-w-7xl lg:px-8">
            <div className="space-y-12 lg:grid  lg:grid-cols-3 lg:gap-x-8 lg:gap-y-8 lg:space-y-0">
              {/*1*/}
              <a
                ref={ref28}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible28
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/company/dev"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/hover/logodev.png"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref29}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible29
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Web Développement
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref30}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible30
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/company/audio"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/hover/logoaud.png"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref31}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible31
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  AudioVisuel
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref32}
                className={`relative  max-w-xl flex h-80   hover:opacity-75  flex-col overflow-hidden rounded-lg p-6   xl:w-auto ${
                  isVisible32
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/company/print"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/hover/logoprint.png"
                      alt=""
                      className="h-full w-full object-cover    object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref33}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible33
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Impression & Publicité
                </span>
              </a>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}
