/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          sky: colors.sky,
          teal: colors.teal,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useRef, useState } from "react";
import { Disclosure, Menu, Switch, Transition } from "@headlessui/react";
import { db } from "../config/firebase";
import {
  Bars3Icon,
  BellIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { collection, addDoc } from "firebase/firestore";
import NavBar from "../Components/Navbar";
import { Helmet } from "react-helmet";
const user = {
  name: "Debbie Lewis",
  handle: "deblewis",
  email: "debbielewis@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80",
};
const navigation = [
  { name: "Inscription", href: "#", current: true },
  { name: "Morserv", href: "https://morserv.com/", current: false },
  {
    name: "Services",
    href: "https://morserv.com/offreScreen/",
    current: false,
  },
  { name: "Boutique", href: "https://morserv.com/market", current: false },
];
const subNavigation = [
  { name: "Profile", href: "#", icon: UserCircleIcon, current: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InscrptionScreen() {
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",

    adress: "",
    numTlp: "",
    anXper: "",
    nbrEmbl: "",
  });

  const formRef = useRef();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Obtenez une référence à la collection "form"
  const formCollectionRef = collection(db, "formulaire");
  const [selectedOption, setSelectedOption] = useState("oui");
  const [selectedJob, setSelectedJob] = useState("");
  // Fonction pour gérer la soumission du formulaire

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleJobChange = (event) => {
    setSelectedJob(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const nom = e.target.elements.nom?.value;
    const prenom = e.target.elements.prenom?.value;
    const adress = e.target.elements.adress?.value;
    const numTlp = e.target.elements.numTlp?.value;
    const anXper = e.target.elements.anXper?.value;
    const nbrEmbl = e.target.elements.nbrEmbl?.value;
    // Check if the required fields have valid values
    if (!nom || !prenom || !adress || !numTlp || !anXper || !nbrEmbl) {
      console.error("Please fill in all required fields.");
      return;
    }

    try {
      // Perform the Firestore document write operation
      await addDoc(collection(db, "formulaire"), {
        nom,
        prenom,
        adress,
        numTlp,
        anXper,
        nbrEmbl,
        selectedOption,
        selectedJob,
      });

      console.log("Document written successfully.");
    } catch (error) {
      console.error("Error writing document:", error);
    }

    setSelectedOption("option1");
    setSelectedJob("");
    if (formRef.current) {
      formRef.current.reset();
    }
    setFormData({
      nom: "",
      prenom: "",

      adress: "",
      numTlp: "",
      anXper: "",
      nbrEmbl: "",
    });
  };

  // Définissez les données à écrire dans Firestore

  return (
    <div>
      <div>
        <Helmet>
          <title>Inscrption Employée</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <Disclosure
        as="div"
        className="relative overflow-hidden bg-sky-700 pb-32"
      >
        {({ open }) => (
          <>
            <NavBar />
            <div
              aria-hidden="true"
              className={classNames(
                open ? "bottom-0" : "inset-y-0",
                "absolute inset-x-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:inset-y-0"
              )}
            >
              <div className="absolute inset-0 flex">
                <div
                  className="h-full w-1/2"
                  style={{ backgroundColor: "#0a527b" }}
                />
                <div
                  className="h-full w-1/2"
                  style={{ backgroundColor: "#065d8c" }}
                />
              </div>
              <div className="relative flex justify-center">
                <svg
                  className="flex-shrink-0"
                  width={1750}
                  height={308}
                  viewBox="0 0 1750 308"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M284.161 308H1465.84L875.001 182.413 284.161 308z"
                    fill="#0369a1"
                  />
                  <path
                    d="M1465.84 308L16.816 0H1750v308h-284.16z"
                    fill="#065d8c"
                  />
                  <path
                    d="M1733.19 0L284.161 308H0V0h1733.19z"
                    fill="#0a527b"
                  />
                  <path
                    d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z"
                    fill="#0a4f76"
                  />
                </svg>
              </div>
            </div>
            <header className="relative py-10">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div class="grid grid-cols-2  ">
                  <h1 className="text-3xl font-bold tracking-tight text-white">
                    Inscription
                  </h1>
                  <h1 className="text-right  text-3xl font-bold tracking-tight text-white">
                    التسجيل
                  </h1>
                </div>
              </div>
            </header>
          </>
        )}
      </Disclosure>

      <main className="relative -mt-32">
        <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-3">
                <nav className="space-y-1">
                  {subNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700"
                          : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                        "group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? "text-teal-500 group-hover:text-teal-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </aside>

              <form
                ref={formRef}
                onSubmit={handleSubmit}
                className="divide-y divide-gray-200 lg:col-span-9"
              >
                <div className="py-6 px-4 sm:p-6 lg:pb-8">
                  <div>
                    <h1 className="text-right text-lg font-medium leading-6 text-gray-900">
                      مرحبا بكم في صفحة تسجيل الحرفيين لدينا
                    </h1>
                    <p className="text-right mt-1 text-sm text-gray-500">
                      إذا كنت حرفيًا ، يمكنك الانضمام إلينا عن طريق ملء النموذج
                      أدناه ، و سوف نتصل بك لاحقًا للتأكيد
                    </p>
                  </div>

                  <div className="mt-6 flex flex-col lg:flex-row">
                    <div className="flex-grow space-y-6"></div>
                  </div>

                  <div className="mt-6 grid grid-cols-12 gap-6">
                    <div className="col-span-12 sm:col-span-6">
                      <div class="grid grid-cols-2  ">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Nom
                        </label>
                        <p
                          htmlFor="last-name"
                          className="text-right  block text-sm font-medium text-gray-700"
                        >
                          الإسم
                        </p>
                      </div>
                      <input
                        onChange={handleChange}
                        type="text"
                        name="nom"
                        autoComplete="family-name"
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-12 sm:col-span-6">
                      <div class="grid grid-cols-2  ">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Prénom
                        </label>
                        <p
                          htmlFor="last-name"
                          className="text-right  block text-sm font-medium text-gray-700"
                        >
                          اللقب
                        </p>
                      </div>
                      <input
                        type="text"
                        name="prenom"
                        autoComplete="family-name"
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12">
                      <div class="grid grid-cols-2  ">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Adresse
                        </label>
                        <p
                          htmlFor="last-name"
                          className="text-right  block text-sm font-medium text-gray-700"
                        >
                          العنوان
                        </p>
                      </div>
                      <input
                        type="text"
                        name="adress"
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-12 sm:col-span-6">
                      <div class="grid grid-cols-2  ">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Numéro Téléphone
                        </label>
                        <p
                          htmlFor="last-name"
                          className="text-right  block text-sm font-medium text-gray-700"
                        >
                          رقم الهاتف
                        </p>
                      </div>
                      <input
                        type="text"
                        name="numTlp"
                        autoComplete="organization"
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-2 m-3 ">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Choisis
                    </label>
                    <p
                      htmlFor="last-name"
                      className="text-right  block text-sm font-medium text-gray-700"
                    >
                      إختر
                    </p>
                  </div>
                  <select
                    id="countries"
                    class="bg-gray-50 border border-gray-300   text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    value={selectedJob}
                    onChange={handleJobChange}
                  >
                    <option selected>Choisi un métier</option>
                    <option value="PLOBM">Plomberie (الترصيص) </option>
                    <option value="PEINT">Peinture (الطلاء)</option>
                    <option value="FROI">Froid (التبريد)</option>
                    <option value="ElEC">Eléctricité (الكهرباء)</option>
                    <option value="MAC">Maçonnerie (البناء)</option>
                    <option value="SOD">Soudure (التلحيم)</option>
                    <option value="ALUM">Aliminium (الألمنيوم)</option>
                    <option value="PLACO">Placo (بلاكو بلاتر) </option>
                    <option value="PLACO">Menuisier (النجارة) </option>
                    <option value="IMPR">Prabole (باربول)</option>
                    <option value="IMPR">Nettoyage (التنظيف)</option>
                    <option value="IMPR">Photographe (التصوير )</option>
                    <option value="DEV">Développement (البرمجة)</option>
                    <option value="IMPR">Impression (الطباعة)</option>
                  </select>

                  <div className="mt-6 grid grid-cols-12 gap-6">
                    <div className="col-span-12 sm:col-span-6">
                      <div class="grid grid-cols-2  ">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Années D'éxperience
                        </label>
                        <p
                          htmlFor="last-name"
                          className="text-right  block text-sm font-medium text-gray-700"
                        >
                          سنوات الخبرة
                        </p>
                      </div>
                      <input
                        type="text"
                        name="anXper"
                        autoComplete="given-name"
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-12 sm:col-span-6">
                      <div class="grid grid-cols-2  ">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Nombre D'emloyées
                        </label>
                        <p
                          htmlFor="last-name"
                          className="text-right  block text-sm font-medium text-gray-700"
                        >
                          عدد العمال
                        </p>
                      </div>
                      <input
                        type="text"
                        name="nbrEmbl"
                        autoComplete="family-name"
                        className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-12 sm:col-span-6"></div>
                  </div>
                  <div class="grid grid-cols-2  ">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Avez-vous un véhicule ?
                    </label>
                    <p
                      htmlFor="last-name"
                      className="text-right  block text-sm font-medium text-gray-700"
                    >
                      هل لديك مركبة ؟
                    </p>
                  </div>

                  <div class="flex items-center mb-4">
                    <label class="m-3">
                      <input
                        type="radio"
                        name="option"
                        value="oui"
                        checked={selectedOption === "oui"}
                        onChange={handleOptionChange}
                        defaultChecked
                      />
                      {"  "} <label htmlFor="oui">نعم</label>
                    </label>

                    <label>
                      <input
                        type="radio"
                        name="option"
                        value="non"
                        checked={selectedOption === "non"}
                        onChange={handleOptionChange}
                      />
                      {"  "} <label htmlFor="non">لا</label>
                    </label>
                  </div>
                </div>

                {/* Privacy section */}
                <div className="divide-y divide-gray-200 pt-6">
                  <div className="mt-4 flex justify-end py-4 px-4 sm:px-6">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    >
                      إلغاء
                    </button>
                    <button
                      type="submit"
                      className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-sky-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    >
                      حفظ
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
