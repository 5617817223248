/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

// Initialisez Algolia
const searchClient = algoliasearch(
  "N0JKV9SSXH",
  "69c01578b8a4c7bd03b507c542a48e11"
);
const searchIndex = searchClient.initIndex("produits");
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchComponent() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [allProducts, setAllProducts] = useState([]); // Pour stocker tous les produits initiaux

  useEffect(() => {
    // Fonction pour récupérer tous les produits
    async function fetchAllProducts() {
      try {
        const allProductsResult = await searchIndex.search("");
        setAllProducts(allProductsResult.hits);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de tous les produits Algolia :",
          error
        );
        toast.error(
          "Erreur lors de la récupération de tous les produits Algolia :",
          error
        );
      }
    }

    fetchAllProducts();
  }, []); // Utilisez un tableau vide ici pour exécuter cette requête une seule fois lors du montage du composant

  useEffect(() => {
    async function performSearch() {
      try {
        if (query.trim() === "") {
          // Si la requête est vide, affichez tous les produits
          setResults(allProducts);
        } else {
          const result = await searchIndex.search(query);
          setResults(result.hits);
        }
      } catch (error) {
        console.error("Erreur de recherche Algolia :", error);
      }
    }

    performSearch();
  }, [query, allProducts]); // Exécutez cette fonction à chaque modification de la requête ou des produits

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);
  };

  return (
    <div>
      <div>
        <Helmet>
          <title>Recherche</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <header>
        <Disclosure as="nav" className="bg-white shadow">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex px-2 lg:px-0">
                    <div className="flex flex-shrink-0 items-center">
                      <a href="/">
                        <img
                          className="block h-12 w-auto lg:hidden"
                          src="./images/logoNoir.png"
                          alt="morserv sétif"
                        />
                        <img
                          className="hidden h-12 w-auto lg:block"
                          src="./images/logoNoir2.png"
                          alt="Your Company"
                        />
                      </a>
                    </div>
                    <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                      {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                      <a
                        href="/"
                        className="inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
                      >
                        Accueil
                      </a>
                      <a
                        href="/services"
                        className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      >
                        Services
                      </a>
                      <a
                        href="/company"
                        className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      >
                        Entreprise
                      </a>
                      <a
                        href="/contact"
                        className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      >
                        Contact
                      </a>
                    </div>
                    <div className="hidden lg:ml-6 lg:flex lg:space-x-20"></div>
                  </div>
                  <div className="flex flex-1 items-center justify-center px-2 lg:ml-6  ">
                    <div className="w-full max-w-lg lg:max-w-xs">
                      <label htmlFor="search" className="sr-only">
                        Search
                      </label>
                      <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <MagnifyingGlassIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="search"
                          name="search"
                          className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-slate-800 sm:text-sm"
                          placeholder="Recherche"
                          value={query}
                          onChange={handleInputChange}
                          type="search"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="hidden lg:ml-4 lg:flex lg:items-center">
                    {/* Profile dropdown */}
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="space-y-1 pt-2 pb-3">
                  {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
                  <Disclosure.Button
                    as="a"
                    href="/"
                    className="block border-l-4 border-slate-200 bg-slate-50 py-2 pl-3 pr-4 text-base font-medium hover:bg-slate-200 text-slate-800"
                  >
                    Accueil
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="/services"
                    className="block border-l-4 border-slate-200 bg-slate-50 py-2 pl-3 pr-4 text-base font-medium hover:bg-slate-200 text-slate-800"
                  >
                    Tout Services
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="/contact"
                    className="block border-l-4 border-slate-200 bg-slate-50 py-2 pl-3 pr-4 text-base font-medium hover:bg-slate-200 text-slate-800"
                  >
                    Contact
                  </Disclosure.Button>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </header>
      <main>
        <div className="mx-auto max-w-xl px-4 pt-24 sm:px-6 sm:pt-32 lg:max-w-7xl lg:px-8">
          <div className="space-y-12 lg:grid grid-rows-2 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-8 lg:space-y-0">
            {results.map((result) => (
              <a
                className="relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto"
                href={`/offre/${result.code}`}
              >
                {" "}
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src={result.imageUrl}
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span className="relative mt-auto text-center text-xl font-bold text-white ">
                  {result.nom}
                </span>
              </a>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}
