import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

import { collection, addDoc } from "firebase/firestore";

import { Fragment, useEffect, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { db, storage } from "../config/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const navigation = [
  { name: "Qui sommes-nous ?  ", href: "/aboutus" },
  { name: "Notre identité de marque", href: "/about" },
  { name: "Nos solutions", href: "/solution" },
  { name: "Merchandising et PLV Pharmacie", href: "/merchan" },
];
export default function AdminProduct() {
  const [selectedImage, setSelectedImage] = useState(null);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [formData, setFormData] = useState({
    nom: "",
    descrption: "",
    branche: "",
    code: "",
  });

  const formRef = useRef();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleChange = (event) => {
    const { nom, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [nom]: value,
    }));
  };

  const formCollectionRef = collection(db, "produits");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nom = e.target.elements.nom?.value;
    const descrption = e.target.elements.descrption?.value;
    const branche = e.target.elements.branche?.value;
    const code = e.target.elements.code?.value;

    // Check if the required fields have valid values
    if (!nom || !descrption || !branche || !code) {
      console.error("Please fill in all required fields.");
      return;
    }

    try {
      if (selectedImage) {
        const storageRef = ref(storage, `images/${selectedImage.name}`);
        await uploadBytes(storageRef, selectedImage);
        const downloadURL = await getDownloadURL(storageRef);

        // Now, you can add the form data along with the image URL to Firestore
        await addDoc(collection(db, "produits"), {
          nom,
          descrption,
          branche,
          code,
          imageUrl: downloadURL, // Add the image URL here
        });

        console.log("Document with image written successfully.");
      } else {
        // Handle the case when no image is selected
        console.error("No image selected.");
      }
    } catch (error) {
      console.error("Error writing document:", error);
    }

    if (formRef.current) {
      formRef.current.reset();
    }
    setSelectedImage(null);
    setFormData({
      nom: "",
      descrption: "",
      branche: "",
      code: "",
    });
  };

  return (
    <div className="relative overflow-hidden">
      <div className="mx-auto max-w-7xl py-16   sm:py-24 lg:px-8">
        <div className="relative bg-white shadow-xl">
          <h2 className="sr-only">Ajouté Un Produit</h2>

          <div className="grid grid-cols-1 lg:grid-cols-3">
            <div className="relative overflow-hidden bg-gradient-to-l from-cyan-600 to-cyan-800  py-10 px-6 sm:px-10 xl:p-12">
              <div
                className="pointer-events-none absolute inset-0 sm:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={343}
                  height={388}
                  viewBox="0 0 343 388"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                    fill="url(#linear1)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear1"
                      x1="254.553"
                      y1="107.554"
                      x2="961.66"
                      y2="814.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={359}
                  height={339}
                  viewBox="0 0 359 339"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                    fill="url(#linear2)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear2"
                      x1="192.553"
                      y1="28.553"
                      x2="899.66"
                      y2="735.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>

              <h3 className="mt-6 text-lg font-medium text-white">
                Ajouté un Service
              </h3>
            </div>

            {/* Contact form */}
            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 className="text-lg font-medium text-gray-900">Formulaire </h3>
              <form
                onSubmit={handleSubmit}
                ref={formRef}
                action="#"
                className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <div>
                  <label
                    type="nom"
                    name="nom"
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Nom de Produit
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="nom"
                      id="nom"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                </div>
                <div>
                  <label
                    type="code"
                    name="code"
                    htmlFor="code"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Code
                  </label>
                  <div className="mt-1">
                    <input
                      id="code"
                      name="code"
                      type="code"
                      autoComplete="code"
                      className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    type="branche"
                    name="branche"
                    htmlFor="subject"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Branche
                  </label>
                  <div className="mt-1">
                    <input
                      type="branche"
                      name="branche"
                      id="branche"
                      className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                </div>

                <div class="flex items-center justify-center w-full sm:col-span-2">
                  <label
                    htmlFor="image"
                    for="dropzone-file"
                    class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span class="font-semibold">Click to upload</span> or
                        drag and drop
                      </p>
                      <p class="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      name="image"
                      onChange={handleImageChange}
                      accept="image/*"
                      class="hidden"
                    ></input>
                  </label>
                </div>

                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      type="descrption"
                      name="descrption"
                      htmlFor="descrption"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Description
                    </label>
                    <span id="message-max" className="text-sm text-gray-500">
                      Max. 500 caractères
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="descrption"
                      name="descrption"
                      rows={4}
                      className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      aria-describedby="message-max"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2 sm:flex sm:justify-end">
                  <button
                    type="submit"
                    className="  mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-cyan-700 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="mt-10"></div>
      </div>
    </div>
  );
}
