import { Fragment, useEffect, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet";

export default function ServicesScreen() {
  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  const ref2 = useRef();
  const isVisible2 = useIsVisible(ref2);

  const ref3 = useRef();
  const isVisible3 = useIsVisible(ref3);

  const ref4 = useRef();
  const isVisible4 = useIsVisible(ref4);

  const ref5 = useRef();
  const isVisible5 = useIsVisible(ref5);

  const ref6 = useRef();
  const isVisible6 = useIsVisible(ref6);

  const ref7 = useRef();
  const isVisible7 = useIsVisible(ref7);

  const ref8 = useRef();
  const isVisible8 = useIsVisible(ref8);

  const ref9 = useRef();
  const isVisible9 = useIsVisible(ref9);

  const ref10 = useRef();
  const isVisible10 = useIsVisible(ref10);

  const ref11 = useRef();
  const isVisible11 = useIsVisible(ref11);

  const ref12 = useRef();
  const isVisible12 = useIsVisible(ref12);

  const ref13 = useRef();
  const isVisible13 = useIsVisible(ref13);

  const ref14 = useRef();
  const isVisible14 = useIsVisible(ref14);

  const ref15 = useRef();
  const isVisible15 = useIsVisible(ref15);

  const ref16 = useRef();
  const isVisible16 = useIsVisible(ref16);

  const ref17 = useRef();
  const isVisible17 = useIsVisible(ref17);

  const ref18 = useRef();
  const isVisible18 = useIsVisible(ref18);

  const ref19 = useRef();
  const isVisible19 = useIsVisible(ref19);

  const ref20 = useRef();
  const isVisible20 = useIsVisible(ref20);

  const ref21 = useRef();
  const isVisible21 = useIsVisible(ref21);

  const ref22 = useRef();
  const isVisible22 = useIsVisible(ref22);

  const ref23 = useRef();
  const isVisible23 = useIsVisible(ref23);

  const ref24 = useRef();
  const isVisible24 = useIsVisible(ref24);

  const ref25 = useRef();
  const isVisible25 = useIsVisible(ref25);

  const ref26 = useRef();
  const isVisible26 = useIsVisible(ref26);

  const ref27 = useRef();
  const isVisible27 = useIsVisible(ref27);

  const ref28 = useRef();
  const isVisible28 = useIsVisible(ref28);

  const ref29 = useRef();
  const isVisible29 = useIsVisible(ref29);

  const ref30 = useRef();
  const isVisible30 = useIsVisible(ref30);

  const ref31 = useRef();
  const isVisible31 = useIsVisible(ref31);

  const ref32 = useRef();
  const isVisible32 = useIsVisible(ref32);

  const ref33 = useRef();
  const isVisible33 = useIsVisible(ref33);
  return (
    <div className="bg-white">
      <div>
        <Helmet>
          <title>Services</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <header className="relative bg-sky-800 pb-24 sm:pb-32">
        <div className="absolute inset-0">
          <img
            className="h-full w-full object-cover"
            src="./images/servs.jpg"
            alt=""
          />
          <div
            className="absolute inset-0 bg-gradient-to-l from-sky-700 to-gray-600 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>

        <NavBar />

        <div className="relative mx-auto mt-24 max-w-md px-6 sm:mt-32 sm:max-w-3xl lg:max-w-7xl lg:px-8">
          <h1
            ref={ref5}
            className={`text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl ${
              isVisible5
                ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                : "opacity-0 invisible   "
            }`}
          >
            Nos Services
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-cyan-100"></p>
        </div>
      </header>

      <main>
        <section
          aria-labelledby="category-heading"
          className="mb-32 xl:mx-auto xl:max-w-7xl xl:px-8"
        >
          <div className="mx-auto max-w-xl px-4 pt-24 sm:px-6 sm:pt-32 lg:max-w-7xl lg:px-8">
            <div className="space-y-12 lg:grid grid-rows-2 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-8 lg:space-y-0">
              <a
                ref={ref8}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible8
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/offre/plomberie"
              >
                {" "}
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/plomb.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref9}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible9
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  La Plomberie
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref10}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible10
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/offre/maconnerie"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/macon.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref11}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible11
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  La Maçonnerie
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref12}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible12
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/offre/peintre"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/peintr.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref13}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible13
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  La peinture
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref14}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible14
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/offre/electric"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/elec.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref15}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible15
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  L'éctricité
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref22}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible22
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/offre/froid"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/froid.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref23}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible23
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Froid
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref16}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible16
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/offre/placo"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/placo.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref17}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible17
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Placo Platre
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref18}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible18
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/offre/nettoyage"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/neto.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref19}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible19
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Nettoyage
                </span>
              </a>

              {/*1*/}
              <a
                ref={ref20}
                className={`relative  max-w-xl flex h-80   flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto ${
                  isVisible20
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
                href="/offre/livraison"
              >
                <div className="hover:opacity-75">
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src="./images/desc/livr.jpg"
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                  />
                </div>
                <span
                  ref={ref21}
                  className={`relative mt-auto text-center text-xl font-bold text-white ${
                    isVisible21
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  La livraison
                </span>
              </a>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}
