import { useEffect, useRef, useState } from "react";
import { Disclosure, Tab } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/20/solid";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
} from "@firebase/firestore";
import { auth, db } from "../config/firebase";
import { useNavigate, useParams } from "react-router-dom";
import Related from "../Components/Related";
import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import NavBar2 from "../Components/Navbar2";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const OffreScreen = () => {
  const parms = useParams();
  const { lin } = parms;
  const [product, setForms] = useState([]);
  const formu = collection(db, "produits");

  const navigate = useNavigate();
  const user = auth.currentUser;
  useEffect(() => {
    const queryMessages = query(formu);
    const unsubscribe = onSnapshot(queryMessages, (snapshot) => {
      let offre = [];
      snapshot.forEach((doc) => {
        offre.push({ ...doc.data() });
      });
      const filteredOffre = offre.filter((item) => item.code === lin);
      setForms(filteredOffre);
      console.log("Forms updated:", filteredOffre);
    });
    return () => {
      console.log("Unsubscribed");
      unsubscribe();
    };
  }, [lin]);

  const addToCart = async (product) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        navigate("/signin");
        return;
      }

      const userId = user.uid;

      const cartItemRef = doc(db, "carts", userId);
      const cartDoc = await getDoc(cartItemRef);
      console.log("Cart document:", cartDoc.data());
      if (cartDoc.exists()) {
        const updatedCart = [...cartDoc.data().items, product];

        await setDoc(cartItemRef, { userId, items: updatedCart });
      } else {
        await setDoc(cartItemRef, { userId, items: [product] });
      }

      toast.success("Product added to cart.");
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };

  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }

  const ref2 = useRef();
  const isVisible2 = useIsVisible(ref2);

  const ref3 = useRef();
  const isVisible3 = useIsVisible(ref3);

  const ref4 = useRef();
  const isVisible4 = useIsVisible(ref4);

  const ref5 = useRef();
  const isVisible5 = useIsVisible(ref5);

  return (
    <div className="bg-white">
      <div>
        <Helmet>
          <title> {lin}</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <NavBar2 />
      <main className="mx-auto max-w-7xl sm:px-6 sm:pt-16 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
            <Tab.Group as="div" className="flex flex-col-reverse">
              <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
                {product.map((collection) => (
                  <Tab.Panel>
                    <img
                      src={collection.imageUrl}
                      alt="sétif"
                      className="h-full w-full object-cover object-center sm:rounded-lg"
                    />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>

            {/* Product info */}
            <div
              ref={ref5}
              className={`mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0 ${
                isVisible5
                  ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                  : "opacity-0 invisible   "
              }`}
            >
              {product.map((collection) => (
                <div>
                  <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                    {collection.nom}
                  </h1>
                  <div className="mt-3">
                    <h2 className="sr-only">Product information</h2>
                    <p className="text  tracking-tight text-gray-900">
                      {collection.branche}
                    </p>
                  </div>
                  <div className="mt-3">
                    <h3 className="sr-only">Reviews</h3>
                    <div className="flex items-center">
                      <div className="flex items-center">
                        {[0, 1, 2, 3, 4].map((rating) => (
                          <StarIcon
                            key={rating}
                            className={classNames(
                              collection.rating > rating
                                ? "text-slate-600"
                                : "text-gray-300",
                              "h-5 w-5 flex-shrink-0"
                            )}
                            aria-hidden="true"
                          />
                        ))}
                      </div>
                      <p className="sr-only">
                        {collection.rating} out of 5 stars
                      </p>
                    </div>
                  </div>
                  <div className="mt-6">
                    <h3 className="sr-only">Description</h3>

                    <div
                      className="space-y-6 text-base text-gray-700"
                      dangerouslySetInnerHTML={{
                        __html: collection.descrption,
                      }}
                    />
                  </div>{" "}
                </div>
              ))}

              <form className="mt-6">
                <div className="flex flex-col items-center">
                  <button
                    onClick={() => addToCart(product[0])}
                    type="button"
                    className="  mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-slate-700 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Ajouté Le Service au Panier
                  </button>
                </div>
              </form>

              <section aria-labelledby="details-heading" className="mt-12">
                <h2 id="details-heading" className="sr-only">
                  Additional details
                </h2>

                <div className="divide-y divide-gray-200 border-t">
                  {product.map((deta) => (
                    <div>
                      <Disclosure as="div" key={deta.name}>
                        {({ open }) => (
                          <>
                            <h3>
                              <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                                <span
                                  className={classNames(
                                    open ? "text-blue-600" : "text-gray-900",
                                    "text-sm font-medium"
                                  )}
                                >
                                  Aspects
                                </span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon
                                      className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusIcon
                                      className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel
                              as="div"
                              className="prose prose-sm pb-6"
                            >
                              <ul role="list">
                                {deta.details.map((item) => (
                                  <li key={item}>{item}</li>
                                ))}
                              </ul>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div>

          <section
            aria-labelledby="related-heading"
            className="mt-10 border-t border-gray-200 py-16 px-4 sm:px-0"
          >
            <h2
              ref={ref4}
              className={`text-xl font-bold text-gray-900 ${
                isVisible4
                  ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                  : "opacity-0 invisible   "
              }`}
              id="related-heading"
            >
              Recommandation
            </h2>

            <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
              <div
                ref={ref3}
                className={`  ${
                  isVisible3
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                {product.map((prod) => (
                  <div key={prod.id}>
                    <Related prod={prod.related}></Related>
                  </div>
                ))}
              </div>{" "}
              <div
                ref={ref2}
                className={`  ${
                  isVisible2
                    ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                {product.map((prod) => (
                  <div key={prod.id}>
                    <Related prod={prod.related1}></Related>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </main>

      <Footer />
    </div>
  );
};
export default OffreScreen;
