export default function Example() {
  return (
    <div className="bg-gray-50 sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Panier est vide
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            Vous pouvez revenir à notre service, choisir le service approprié,
            puis passer la commande
          </p>
        </div>
        <div className="mt-5">
          <a
            href="/services"
            type="button"
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
          >
            Nos Services
          </a>
        </div>
      </div>
    </div>
  );
}
