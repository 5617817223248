import { useEffect, useState } from "react";
import { db } from "../config/firebase";
import { collection, getDocs, onSnapshot, orderBy } from "firebase/firestore";
import { query } from "firebase/database";

export default function AdminCommandeScreen() {
  const [formulaire, setForms] = useState([]);
  const formu = collection(db, "order");

  useEffect(() => {
    console.log("Effect ran"); // Ajout d'une instruction console.log pour vérifier si l'effet se déclenche

    const queryMessages = query(formu);
    const unsubscribe = onSnapshot(queryMessages, (snapshot) => {
      let formulaire = [];
      snapshot.forEach((doc) => {
        formulaire.push({ ...doc.data(), id: doc.id });
      });
      setForms(formulaire);

      console.log("Forms updated:", formulaire); // Ajout d'une instruction console.log pour vérifier les nouvelles valeurs de `forms`
    });

    return () => {
      console.log("Unsubscribed"); // Ajout d'une instruction console.log pour vérifier si la désinscription se produit
      unsubscribe();
    };
  }, []);

  return (
    <main className="bg-white px-4 pt-16 pb-24 sm:px-6 sm:pt-24 lg:px-8 lg:py-32">
      <div className="mx-auto max-w-3xl">
        <div className="max-w-xl">
          <p className="mt-2 text-4xl font-bold text-sky-600 tracking-tight">
            Commande
          </p>
        </div>

        <section
          aria-labelledby="order-heading"
          className="mt-10 border-t border-gray-200"
        >
          <h2 id="order-heading" className="sr-only">
            Your order
          </h2>

          <h3 className="sr-only">Items</h3>
          {formulaire.map((product) => (
            <div
              key={product.adress}
              className="flex flex-col space-y-6 border-b border-gray-200 py-10 sm:flex-row sm:space-y-0 sm:space-x-6"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/e-commerce-react-morserv.appspot.com/o/20231007135551_%5Bfpdl.in%5D_investment-growth-3d-illustration_376542-1397_medium.jpg?alt=media&token=1e8b9f81-eedc-4b73-8b50-63035ed92cc0&_gl=1*ktnmzp*_ga*NjI3MjAxMjQuMTY5NTcyNTk1NA..*_ga_CW55HF8NVT*MTY5NjY3MDMxOC4yMS4xLjE2OTY2NzQzODkuNDguMC4w"
                alt="ww"
                className="h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40"
              />
              <div className="flex flex-auto flex-col">
                <div>
                  <h4 className="font-medium text-3xl text-gray-900">
                    {product.user.fullName}
                  </h4>

                  <div className="flex flex-wrap">
                    <span className="font-medium text-gray-900">
                      Adresse :{" "}
                    </span>
                    <p className="ml-2 text-gray-700">{product.adress}</p>
                  </div>
                  <div className="flex flex-wrap">
                    <span className="font-medium text-gray-900">Offre : </span>{" "}
                    {product.userCart.map((offre) => (
                      <p>
                        {offre.items.map((offr) => (
                          <p>{offr.nom}</p>
                        ))}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="max-w-xl">
                  <p className="mt-2 text-2xl font-bold text-slate-400 tracking-tight">
                    Contact Client
                  </p>
                </div>
                <div className="mt-6 flex flex-wrap items-center">
                  <div className="flex">
                    <span className="font-medium text-gray-900">Phone: </span>
                    <p className="ml-2 text-gray-700">{product.nbr}</p>
                  </div>
                </div>
                <div className="flex mt-2">
                  <span className="font-medium text-gray-900">Email: </span>
                  <p className="ml-2 text-gray-700">{product.user.email}</p>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
    </main>
  );
}
