import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { db } from "../config/firebase";
import { collection, addDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import MapComponent from "../Components/MapComponent";
import NavBar from "../Components/Navbar";

export default function Contact() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    phone: "",
    sujet: "",
    ville: "",
    mssg: "",
  });

  const formRef = useRef();

  const handleChange = (event) => {
    const { nom, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [nom]: value,
    }));
  };

  const formCollectionRef = collection(db, "formulaire");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nom = e.target.elements.nom?.value;
    const prenom = e.target.elements.prenom?.value;
    const email = e.target.elements.email?.value;
    const phone = e.target.elements.phone?.value;
    const sujet = e.target.elements.sujet?.value;
    const ville = e.target.elements.ville?.value;
    const mssg = e.target.elements.mssg?.value;
    // Check if the required fields have valid values
    if (!nom || !prenom || !email || !phone || !sujet || !ville || !mssg) {
      console.error("Please fill in all required fields.");
      return;
    }

    try {
      // Perform the Firestore document write operation
      await addDoc(collection(db, "formulaire"), {
        nom,
        prenom,
        email,
        phone,
        sujet,
        ville,
        mssg,
      });

      console.log("Document written successfully.");
    } catch (error) {
      console.error("Error writing document:", error);
    }

    if (formRef.current) {
      formRef.current.reset();
    }
    setFormData({
      nom: "",
      prenom: "",
      email: "",
      phone: "",
      sujet: "",
      ville: "",
      mssg: "",
    });
  };
  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  const ref2 = useRef();
  const isVisible2 = useIsVisible(ref2);

  const ref3 = useRef();
  const isVisible3 = useIsVisible(ref3);

  const ref4 = useRef();
  const isVisible4 = useIsVisible(ref4);

  const ref5 = useRef();
  const isVisible5 = useIsVisible(ref5);

  const ref6 = useRef();
  const isVisible6 = useIsVisible(ref6);

  const ref7 = useRef();
  const isVisible7 = useIsVisible(ref7);

  const ref8 = useRef();
  const isVisible8 = useIsVisible(ref8);

  const ref9 = useRef();
  const isVisible9 = useIsVisible(ref9);

  const ref10 = useRef();
  const isVisible10 = useIsVisible(ref10);

  const ref12 = useRef();
  const isVisible12 = useIsVisible(ref12);

  const ref13 = useRef();
  const isVisible13 = useIsVisible(ref13);

  const ref14 = useRef();
  const isVisible14 = useIsVisible(ref14);

  const ref15 = useRef();
  const isVisible15 = useIsVisible(ref15);

  const ref16 = useRef();
  const isVisible16 = useIsVisible(ref16);

  const ref17 = useRef();
  const isVisible17 = useIsVisible(ref17);

  const ref18 = useRef();
  const isVisible18 = useIsVisible(ref18);

  return (
    <div className="isolate  ">
      <div>
        <Helmet>
          <title>Contact</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <div className="relative overflow-hidden">
        <NavBar />
        <div className="isolate bg-white"></div>
        <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
          <div className="relative bg-white shadow-xl">
            <h2 className="sr-only">Contact us</h2>

            <div className="grid grid-cols-1 lg:grid-cols-3">
              <div className="relative overflow-hidden bg-gradient-to-l from-zinc-600 to-slate-800 py-10 px-6 sm:px-10 xl:p-12">
                <div
                  className="pointer-events-none absolute inset-0 sm:hidden"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 h-full w-full"
                    width={343}
                    height={388}
                    viewBox="0 0 343 388"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                      fill="url(#linear1)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear1"
                        x1="254.553"
                        y1="107.554"
                        x2="961.66"
                        y2="814.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 h-full w-full"
                    width={359}
                    height={339}
                    viewBox="0 0 359 339"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                      fill="url(#linear2)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear2"
                        x1="192.553"
                        y1="28.553"
                        x2="899.66"
                        y2="735.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 h-full w-full"
                    width={160}
                    height={678}
                    viewBox="0 0 160 678"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                      fill="url(#linear3)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear3"
                        x1="192.553"
                        y1="325.553"
                        x2="899.66"
                        y2="1032.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <p
                  ref={ref1}
                  className={`max-w-3xl text-base text-indigo-50  ${
                    isVisible1
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Alors n’attendez plus, faites appel à notre expertise dès
                  maintenant ! Parce que nous réinventons votre succès
                </p>
                <h3
                  ref={ref2}
                  className={`mt-6 text-lg font-medium text-white  ${
                    isVisible2
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Contact information
                </h3>

                <dl className="mt-8 space-y-6">
                  <dt>
                    <span
                      ref={ref3}
                      className={`sr-only  ${
                        isVisible3
                          ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                          : "opacity-0 invisible   "
                      }`}
                    >
                      Phone number
                    </span>
                  </dt>
                  <dd
                    ref={ref4}
                    className={`flex text-base text-indigo-50 ${
                      isVisible4
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <PhoneIcon
                      className="h-6 w-6 flex-shrink-0 text-indigo-200"
                      aria-hidden="true"
                    />
                    <span className="ml-3">+213 (779) 215-955</span>
                  </dd>
                  <dt>
                    <span className="sr-only">Email</span>
                  </dt>
                  <dd
                    ref={ref5}
                    className={`flex text-base text-indigo-50 ${
                      isVisible5
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <EnvelopeIcon
                      className="h-6 w-6 flex-shrink-0 text-indigo-200"
                      aria-hidden="true"
                    />
                    <span className="ml-3">morserv19@gmail.com</span>
                  </dd>
                  <dd
                    ref={ref6}
                    className={`flex text-base text-teal-200 ${
                      isVisible6
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    {" "}
                    <a href="https://wa.me/213770733069">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="currentColor"
                        class="bi bi-whatsapp"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />{" "}
                      </svg>{" "}
                    </a>{" "}
                    <a href="https://wa.me/213770733069" className="ml-3">
                      Communiquez avec nous sur WhatsApp
                    </a>{" "}
                    <a href="https://wa.me/213770733069">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                      </svg>{" "}
                    </a>{" "}
                  </dd>
                </dl>

                <ul
                  ref={ref7}
                  className={`mt-8 flex space-x-12 ${
                    isVisible7
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                  role="list"
                >
                  <li>
                    <a
                      className="text-indigo-200 hover:text-indigo-100"
                      href="https://www.facebook.com/morserv19"
                    >
                      <span className="sr-only">Facebook</span>
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        aria-hidden="true"
                      >
                        <path
                          d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-indigo-200 hover:text-indigo-100"
                      href="https://www.instagram.com/morpub19"
                    >
                      <span className="sr-only">GitHub</span>
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        aria-hidden="true"
                      >
                        <path
                          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-indigo-200 hover:text-indigo-100"
                      href="https://www.linkedin.com/company/morserv/"
                    >
                      <span className="sr-only">Twitter</span>
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        aria-hidden="true"
                      >
                        <path
                          d="M9.42857 8.96884H13.1429V10.8193C13.6783 9.75524 15.0503 8.79887 17.1114 8.79887C21.0623 8.79887 22 10.9167 22 14.8028V22H18V15.6878C18 13.4748 17.4646 12.2266 16.1029 12.2266C14.2143 12.2266 13.4286 13.5722 13.4286 15.6878V22H9.42857V8.96884ZM2.57143 21.83H6.57143V8.79887H2.57143V21.83ZM7.14286 4.54958C7.14286 4.88439 7.07635 5.21593 6.94712 5.52526C6.81789 5.83458 6.62848 6.11565 6.3897 6.3524C6.15092 6.58915 5.86745 6.77695 5.55547 6.90508C5.24349 7.0332 4.90911 7.09915 4.57143 7.09915C4.23374 7.09915 3.89937 7.0332 3.58739 6.90508C3.27541 6.77695 2.99193 6.58915 2.75315 6.3524C2.51437 6.11565 2.32496 5.83458 2.19574 5.52526C2.06651 5.21593 2 4.88439 2 4.54958C2 3.87339 2.27092 3.22489 2.75315 2.74675C3.23539 2.26862 3.88944 2 4.57143 2C5.25341 2 5.90747 2.26862 6.3897 2.74675C6.87194 3.22489 7.14286 3.87339 7.14286 4.54958Z"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>

              {/* Contact form */}
              <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                <h3
                  ref={ref8}
                  className={`text-lg font-medium text-gray-900 ${
                    isVisible8
                      ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Envoyez-nous un message 
                </h3>
                <form
                  onSubmit={handleSubmit}
                  ref={formRef}
                  action="#"
                  method="POST"
                  className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                >
                  <div
                    ref={ref9}
                    className={`  ${
                      isVisible9
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <label
                      type="prenom"
                      name="prenom"
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Prénom
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="prenom"
                        id="prenom"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                  </div>
                  <div
                    ref={ref10}
                    className={`  ${
                      isVisible10
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <label
                      type="nom"
                      name="nom"
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Nom de famille
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="npm"
                        id="nom"
                        autoComplete="family-name"
                        className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                  </div>

                  <div
                    ref={ref12}
                    className={`  ${
                      isVisible12
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <label
                      type="email"
                      name="email"
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-900"
                    >
                      E-mail
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                  </div>
                  <div
                    ref={ref13}
                    className={`  ${
                      isVisible13
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <div className="flex justify-between">
                      <label
                        type="phone"
                        name="phone"
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Téléphone
                      </label>
                      <span
                        id="phone-optional"
                        className="text-sm text-gray-500"
                      >
                        Facultatif
                      </span>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        autoComplete="tel"
                        className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        aria-describedby="phone-optional"
                      />
                    </div>
                  </div>

                  <div
                    ref={ref14}
                    className={`  ${
                      isVisible14
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    {" "}
                    <label
                      type="sujet"
                      name="sujet"
                      htmlFor="subject"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Sujet
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="sujet"
                        id="sujet"
                        className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                  </div>

                  <div
                    ref={ref15}
                    className={`  ${
                      isVisible15
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <label
                      type="ville"
                      name="ville"
                      htmlFor="ville"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Ville
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="ville"
                        id="ville"
                        className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                  </div>

                  <div
                    ref={ref16}
                    className={`sm:col-span-2  ${
                      isVisible16
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <div className="flex justify-between">
                      <label
                        type="mssg"
                        name="mssg"
                        htmlFor="message"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Message
                      </label>
                      <span id="message-max" className="text-sm text-gray-500">
                        Max. 500 caractères
                      </span>
                    </div>
                    <div className="mt-1">
                      <textarea
                        id="mssg"
                        name="mssg"
                        rows={4}
                        className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        aria-describedby="message-max"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div
                    ref={ref17}
                    className={` sm:col-span-2 sm:flex sm:justify-end  ${
                      isVisible17
                        ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <button
                      type="submit"
                      className="  mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-slate-700 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                      Envoyer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            ref={ref18}
            className={` mt-10  ${
              isVisible18
                ? "animate-fade-up animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                : "opacity-0 invisible   "
            }`}
          >
            <MapComponent />
          </div>
        </div>
      </div>
    </div>
  );
}
