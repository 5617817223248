import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import customIconUrl from "../red.svg";
function MapComponent() {
  const position = [36.197232, 5.435474];
  const customIcon = new L.Icon({
    iconUrl: customIconUrl,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
  });

  return (
    <div className="App">
      <MapContainer
        center={position}
        zoom={17}
        style={{ height: "400px", width: "100%" }}
      >
        ,
        <TileLayer
          url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright" > OpenStreetMap </a> contributors'
        />
        <Marker position={position} icon={customIcon}></Marker>
      </MapContainer>
    </div>
  );
}

export default MapComponent;
