import { useEffect, useRef, useState } from "react";
import Footer from "../Components/Footer";
import NavBar2 from "../Components/Navbar2";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }

  return (
    <div className="bg-white">
      <div>
        <Helmet>
          <title>Company</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <header className="relative bg-sky-800 pb-24 sm:pb-32">
        <div className="absolute inset-0">
          <img
            className="h-full w-full object-cover"
            src="./images/app.png"
            alt=""
          />
          <div
            className="absolute inset-0 bg-gradient-to-l from-sky-700 to-gray-600 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>

        <NavBar2 />

        <div className="relative mx-auto mt-24 max-w-md px-6 sm:mt-32 sm:max-w-3xl lg:max-w-7xl lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl ">
            Privacy Policy for morserv
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-cyan-100"></p>
        </div>
      </header>

      <main>
        <section>
          <div className="bg-warm-gray-50">
            <div className="mx-auto max-w-md py-24 px-6 sm:max-w-3xl sm:py-20 lg:max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    Privacy Policy
                  </h2>
                  <p className="mt-4 text-lg text-warm-gray-500">
                    {" "}
                    <a
                      href="#"
                      className="font-medium text-1xl text-cyan-700 hover:text-cyan-600"
                    >
                      Last updated: January 23, 2024
                    </a>
                  </p>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        <p>
                          This Privacy Policy describes Our policies and
                          procedures on the collection, use and disclosure of
                          Your information when You use the Service and tells
                          You about Your privacy rights and how the law protects
                          You. We use Your Personal data to provide and improve
                          the Service. By using the Service, You agree to the
                          collection and use of information in accordance with
                          this Privacy Policy. This Privacy Policy has been
                          created with the help of the Privacy Policy Generator.
                        </p>
                        <br />
                        <div>
                          <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                            Interpretation and Definitions
                          </h2>
                          <p className="mt-4 text-lg text-warm-gray-500">
                            {" "}
                            <a
                              href="#"
                              className="font-medium text-1xl text-cyan-700 hover:text-cyan-600"
                            >
                              Interpretation
                            </a>
                          </p>
                        </div>
                        <p>
                          The words of which the initial letter is capitalized
                          have meanings defined under the following conditions.
                          The following definitions shall have the same meaning
                          regardless of whether they appear in singular or in
                          plural.
                        </p>
                        <br />
                        <div>
                          <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                            Definitions
                          </h2>
                          <p className="mt-4 text-lg text-warm-gray-500">
                            {" "}
                            <a
                              href="#"
                              className="font-medium text-1xl text-cyan-700 hover:text-cyan-600"
                            >
                              For the purposes of this Privacy Policy:
                            </a>
                          </p>
                        </div>
                        <ul className="list-disc">
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">Account</strong> means
                            a unique account created for You to access our
                            Service or parts of our Service.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">Affiliate </strong>{" "}
                            means an entity that controls, is controlled by or
                            is under common control with a party, where
                            "control" means ownership of 50% or more of the
                            shares, equity interest or other securities entitled
                            to vote for election of directors or other managing
                            authority.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">Application </strong>{" "}
                            refers to morserv, the software program provided by
                            the Company.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">Company </strong>{" "}
                            (referred to as either "the Company", "We", "Us" or
                            "Our" in this Agreement) refers to morserv, cite
                            1006 B09 Setif.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold"> Country</strong>{" "}
                            refers to: Algeria
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold"> Device </strong>{" "}
                            means any device that can access the Service such as
                            a computer, a cellphone or a digital tablet.
                          </li>

                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              Personal Data{" "}
                            </strong>{" "}
                            is any information that relates to an identified or
                            identifiable individual.{" "}
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold"> Service</strong>{" "}
                            refers to the Application.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              Service Provider{" "}
                            </strong>{" "}
                            means any natural or legal person who processes the
                            data on behalf of the Company. It refers to
                            third-party companies or individuals employed by the
                            Company to facilitate the Service, to provide the
                            Service on behalf of the Company, to perform
                            services related to the Service or to assist the
                            Company in analyzing how the Service is used.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold"> Usage Data</strong>{" "}
                            refers to data collected automatically, either
                            generated by the use of the Service or from the
                            Service infrastructure itself (for example, the
                            duration of a page visit).
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">You </strong> means
                            the individual accessing or using the Service, or
                            the company, or other legal entity on behalf of
                            which such individual is accessing or using the
                            Service, as applicable.
                          </li>
                        </ul>
                        <br />
                        <div>
                          <h2 className="text-3xl font-bold tracking-tight text-warm-gray-900">
                            Collecting and Using Your Personal Data
                          </h2>
                          <p className="mt-4 text-lg text-warm-gray-500">
                            {" "}
                            <a
                              href="#"
                              className="font-medium text-2xl mb-5  hover:text-cyan-600"
                            >
                              Types of Data Collected
                            </a>{" "}
                            <a
                              href="#"
                              className="font-medium text-xl mb-5  hover:text-cyan-600"
                            >
                              Personal Data
                            </a>
                          </p>
                          <p className="mt-5">
                            While using Our Service, We may ask You to provide
                            Us with certain personally identifiable information
                            that can be used to contact or identify You.
                            Personally identifiable information may include, but
                            is not limited to:
                          </p>

                          <ul className="list-disc">
                            <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                              First name and last name
                            </li>
                            <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                              Phone number
                            </li>
                            <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                              Address, State, Province, ZIP/Postal code, City
                            </li>
                            <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                              Usage Data
                            </li>
                          </ul>

                          <p className="mt-4 text-lg text-warm-gray-500">
                            {" "}
                            <a
                              href="#"
                              className="font-medium text-2xl mb-5  hover:text-cyan-600"
                            >
                              Usage Data
                            </a>
                          </p>
                          <p className="  mt-5  ">
                            Usage Data is collected automatically when using the
                            Service. Usage Data may include information such as
                            Your Device's Internet Protocol address (e.g. IP
                            address), browser type, browser version, the pages
                            of our Service that You visit, the time and date of
                            Your visit, the time spent on those pages, unique
                            device identifiers and other diagnostic data. When
                            You access the Service by or through a mobile
                            device, We may collect certain information
                            automatically, including, but not limited to, the
                            type of mobile device You use, Your mobile device
                            unique ID, the IP address of Your mobile device,
                            Your mobile operating system, the type of mobile
                            Internet browser You use, unique device identifiers
                            and other diagnostic data. We may also collect
                            information that Your browser sends whenever You
                            visit our Service or when You access the Service by
                            or through a mobile device.
                          </p>
                        </div>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="bg-warm-gray-50">
            <div className="mx-auto max-w-md py-12 px-6 sm:max-w-3xl sm:py-20 lg:max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    Information Collected while Using the Application
                  </h2>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        <p>
                          While using Our Application, in order to provide
                          features of Our Application, We may collect, with Your
                          prior permission: Information regarding your location
                          We use this information to provide features of Our
                          Service, to improve and customize Our Service. The
                          information may be uploaded to the Company's servers
                          and/or a Service Provider's server or it may be simply
                          stored on Your device. You can enable or disable
                          access to this information at any time, through Your
                          Device settings.
                        </p>
                        <ul className="list-disc">
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              To provide and maintain our Service
                            </strong>{" "}
                            , including to monitor the usage of our Service.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              {" "}
                              To manage Your Account:{" "}
                            </strong>{" "}
                            to manage Your registration as a user of the
                            Service. The Personal Data You provide can give You
                            access to different functionalities of the Service
                            that are available to You as a registered user.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              For the performance of a contract:
                            </strong>{" "}
                            the development, compliance and undertaking of the
                            purchase contract for the products, items or
                            services You have purchased or of any other contract
                            with Us through the Service.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              {" "}
                              To contact You:{" "}
                            </strong>{" "}
                            To contact You by email, telephone calls, SMS, or
                            other equivalent forms of electronic communication,
                            such as a mobile application's push notifications
                            regarding updates or informative communications
                            related to the functionalities, products or
                            contracted services, including the security updates,
                            when necessary or reasonable for their
                            implementation.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              {" "}
                              To provide You with news,
                            </strong>{" "}
                            special offers and general information about other
                            goods, services and events which we offer that are
                            similar to those that you have already purchased or
                            enquired about unless You have opted not to receive
                            such information.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              {" "}
                              To manage Your requests:{" "}
                            </strong>{" "}
                            To attend and manage Your requests to Us.
                          </li>

                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              For business transfers:
                            </strong>{" "}
                            We may use Your information to evaluate or conduct a
                            merger, divestiture, restructuring, reorganization,
                            dissolution, or other sale or transfer of some or
                            all of Our assets, whether as a going concern or as
                            part of bankruptcy, liquidation, or similar
                            proceeding, in which Personal Data held by Us about
                            our Service users is among the assets transferred.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              {" "}
                              For other purposes:
                            </strong>{" "}
                            We may use Your information for other purposes, such
                            as data analysis, identifying usage trends,
                            determining the effectiveness of our promotional
                            campaigns and to evaluate and improve our Service,
                            products, services, marketing and your experience.
                          </li>
                        </ul>
                        <p>
                          We may share Your personal information in the
                          following situations:{" "}
                        </p>
                        <ul className="list-disc">
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              {" "}
                              With Service Providers:{" "}
                            </strong>{" "}
                            We may share Your personal information with Service
                            Providers to monitor and analyze the use of our
                            Service, to contact You.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              For business transfers:
                            </strong>{" "}
                            We may share or transfer Your personal information
                            in connection with, or during negotiations of, any
                            merger, sale of Company assets, financing, or
                            acquisition of all or a portion of Our business to
                            another company.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              {" "}
                              With Affiliates:
                            </strong>{" "}
                            We may share Your information with Our affiliates,
                            in which case we will require those affiliates to
                            honor this Privacy Policy. Affiliates include Our
                            parent company and any other subsidiaries, joint
                            venture partners or other companies that We control
                            or that are under common control with Us.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              {" "}
                              With other users
                            </strong>{" "}
                            when You share personal information or otherwise
                            interact in the public areas with other users, such
                            information may be viewed by all users and may be
                            publicly distributed outside.
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              {" "}
                              With business partners
                            </strong>{" "}
                            We may share Your information with Our business
                            partners to offer You certain products, services or
                            promotions.
                          </li>{" "}
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            <strong className="font-bold">
                              {" "}
                              With Your consent
                            </strong>{" "}
                            We may disclose Your personal information for any
                            other purpose with Your consent.
                          </li>{" "}
                        </ul>
                        <br />

                        <br />
                      </dd>
                    </div>
                  </dl>
                </div>{" "}
              </div>
              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    Retention of Your Personal Data
                  </h2>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        <p>
                          The Company will retain Your Personal Data only for as
                          long as is necessary for the purposes set out in this
                          Privacy Policy. We will retain and use Your Personal
                          Data to the extent necessary to comply with our legal
                          obligations (for example, if we are required to retain
                          your data to comply with applicable laws), resolve
                          disputes, and enforce our legal agreements and
                          policies. The Company will also retain Usage Data for
                          internal analysis purposes. Usage Data is generally
                          retained for a shorter period of time, except when
                          this data is used to strengthen the security or to
                          improve the functionality of Our Service, or We are
                          legally obligated to retain this data for longer time
                          periods.
                        </p>
                        <br />

                        <br />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    Transfer of Your Personal Data
                  </h2>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        <p>
                          Your information, including Personal Data, is
                          processed at the Company's operating offices and in
                          any other places where the parties involved in the
                          processing are located. It means that this information
                          may be transferred to — and maintained on — computers
                          located outside of Your state, province, country or
                          other governmental jurisdiction where the data
                          protection laws may differ than those from Your
                          jurisdiction. Your consent to this Privacy Policy
                          followed by Your submission of such information
                          represents Your agreement to that transfer. The
                          Company will take all steps reasonably necessary to
                          ensure that Your data is treated securely and in
                          accordance with this Privacy Policy and no transfer of
                          Your Personal Data will take place to an organization
                          or a country unless there are adequate controls in
                          place including the security of Your data and other
                          personal information.
                        </p>
                        <br />

                        <br />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    Delete Your Personal Data
                  </h2>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        <p>
                          You have the right to delete or request that We assist
                          in deleting the Personal Data that We have collected
                          about You. Our Service may give You the ability to
                          delete certain information about You from within the
                          Service. You may update, amend, or delete Your
                          information at any time by signing in to Your Account,
                          if you have one, and visiting the account settings
                          section that allows you to manage Your personal
                          information. You may also contact Us to request access
                          to, correct, or delete any personal information that
                          You have provided to Us. Please note, however, that We
                          may need to retain certain information when we have a
                          legal obligation or lawful basis to do so.
                        </p>
                        <br />

                        <br />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    Disclosure of Your Personal Data
                  </h2>
                  <p className="  max-w-3xl text-xl text-cyan-700">
                    Business Transactions
                  </p>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        <p>
                          If the Company is involved in a merger, acquisition or
                          asset sale, Your Personal Data may be transferred. We
                          will provide notice before Your Personal Data is
                          transferred and becomes subject to a different Privacy
                          Policy.
                        </p>
                        <br />

                        <br />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    Law enforcement
                  </h2>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        <p>
                          Under certain circumstances, the Company may be
                          required to disclose Your Personal Data if required to
                          do so by law or in response to valid requests by
                          public authorities (e.g. a court or a government
                          agency)
                        </p>
                        <br />

                        <br />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    Other legal requirements
                  </h2>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        The Company may disclose Your Personal Data in the good
                        faith belief that such action is necessary to:
                        <ul className="list-disc">
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            Comply with a legal obligation
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            Protect and defend the rights or property of the
                            Company
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            Prevent or investigate possible wrongdoing in
                            connection with the Service
                          </li>{" "}
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            Protect the personal safety of Users of the Service
                            or the public
                          </li>{" "}
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            Protect against legal liability
                          </li>
                          {/* Add more items as needed */}
                        </ul>
                        <br />
                        <br />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    Security of Your Personal Data
                  </h2>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        <p>
                          The security of Your Personal Data is important to Us,
                          but remember that no method of transmission over the
                          Internet, or method of electronic storage is 100%
                          secure. While We strive to use commercially acceptable
                          means to protect Your Personal Data, We cannot
                          guarantee its absolute security.
                        </p>
                        <br />

                        <br />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    Children's Privacy
                  </h2>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        <p>
                          Our Service does not address anyone under the age of
                          13. We do not knowingly collect personally
                          identifiable information from anyone under the age of
                          13. If You are a parent or guardian and You are aware
                          that Your child has provided Us with Personal Data,
                          please contact Us. If We become aware that We have
                          collected Personal Data from anyone under the age of
                          13 without verification of parental consent, We take
                          steps to remove that information from Our servers. If
                          We need to rely on consent as a legal basis for
                          processing Your information and Your country requires
                          consent from a parent, We may require Your parent's
                          consent before We collect and use that information.
                        </p>
                        <br />

                        <br />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    Links to Other Websites
                  </h2>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        <p>
                          Our Service may contain links to other websites that
                          are not operated by Us. If You click on a third party
                          link, You will be directed to that third party's site.
                          We strongly advise You to review the Privacy Policy of
                          every site You visit. We have no control over and
                          assume no responsibility for the content, privacy
                          policies or practices of any third party sites or
                          services.
                        </p>
                        <br />

                        <br />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    Changes to this Privacy Policy
                  </h2>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        <p>
                          We may update Our Privacy Policy from time to time. We
                          will notify You of any changes by posting the new
                          Privacy Policy on this page. We will let You know via
                          email and/or a prominent notice on Our Service, prior
                          to the change becoming effective and update the "Last
                          updated" date at the top of this Privacy Policy. You
                          are advised to review this Privacy Policy periodically
                          for any changes. Changes to this Privacy Policy are
                          effective when they are posted on this page.
                        </p>
                        <br />

                        <br />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div>
                  <h2 className="text-xl font-bold tracking-tight text-warm-gray-900">
                    Contact Us
                  </h2>
                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                  <dl className="space-y-12">
                    <div>
                      <dt className="text-lg font-medium text-warm-gray-900"></dt>
                      <dd className="mt-2 text-base text-left text-warm-gray-500">
                        If you have any questions about this Privacy Policy, You
                        can contact us:
                        <ul className="list-disc">
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            By email: hasnioumortadha@gmail.com
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            By visiting this page on our website:
                            https://morserv.com/contact
                          </li>
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            By phone number: +213770733069
                          </li>{" "}
                          <li className="mb-4 sm:mb-2 md:mb-2 lg:mb-1 xl:mb-1">
                            By mail: cite 1006 B09 Setif
                          </li>{" "}
                        </ul>
                        <br />
                        <br />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}
