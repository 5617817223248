import { BrowserRouter, Route, Routes, Switch } from "react-router-dom";
import React, { useState } from "react";
import "./App.css";
import HomeScreen from "./Screens/HomePage";
import SigninScreen from "./Screens/SigninScreen";
import Login from "./Screens/LoginScreen";
import NotFound from "./Screens/NotFound";
import CartScreen from "./Screens/CartScreen";
import Description from "./Screens/OrderScreen";
import CompanyScreen from "./CompanyScreen";
import Contact from "./Screens/Contact";
import ServicesScreen from "./Screens/ServicesScreen";
import InscrptionScreen from "./Screens/InscrptionScreen";
import SearchComponent from "./Screens/SearchScreen";
import OffreScreen from "./Screens/OffreScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PhoneIcon } from "@heroicons/react/24/outline";
import ReactGA from "react-ga";
import LoginAdminScreen from "./Screens/LoginAdmin";
import RedirectionComponent from "./Screens/Lien";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
function App() {
  const TRACKIND_ID = "G-CKZLE0FG4L";
  ReactGA.initialize(TRACKIND_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
  const telephoneNumber = "+213779215955";
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <div>
          <main>
            <a
              href={`tel:${telephoneNumber}`} // Remplacez telephoneNumber par le numéro de téléphone que vous souhaitez appeler
              className="fixed bottom-8 right-8 rounded-full hover:bg-slate-200 opacity-75   text-white w-14 h-14 flex items-center justify-center cursor-pointer shadow-lg z-50"
            >
              <PhoneIcon
                className="h-6 w-6 flex-shrink-0  text-sky-500   "
                aria-hidden="true"
              />
            </a>
            <Routes>
              <Route path="/cart" element={<CartScreen />} />
              <Route path="/signin" element={<SigninScreen />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/newmorservnewmorpub"
                element={<LoginAdminScreen />}
              />
              <Route path="/company/:code" element={<Description />} />
              <Route path="/offre/:lin" element={<OffreScreen />} />
              <Route path="/contact" element={<Contact />} />

              <Route path="/search" element={<SearchComponent />} />
              <Route path="/inscrption" element={<InscrptionScreen />} />
              <Route
                path="/lienmorservmorpub"
                element={<RedirectionComponent />}
              />
              <Route path="/company" element={<CompanyScreen />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/services" element={<ServicesScreen />} />
              <Route path="/" element={<HomeScreen />} />
              <Route path="*" element={<NotFound />} />
            </Routes>{" "}
          </main>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
