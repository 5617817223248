import { useRef, useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { db } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { addDoc, collection } from "@firebase/firestore";
import NavBar from "../Components/Navbar";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

export default function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const handleSignIn = async (e) => {
    e.preventDefault();

    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;

    if (!email || !password) {
      setErrorMsg("Please fill in all required fields.");
      return;
    }

    const auth = getAuth();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // You can access the signed-in user information using userCredential.user

      // Reset the input fields
      e.target.elements.email.value = "";
      e.target.elements.password.value = "";
      navigate("/");
      // Redirect or perform other actions after successful sign-in
      // For example, you can navigate to a different page.
      // window.location.href = '/dashboard';
    } catch (error) {
      setErrorMsg("Error signing in: " + error.message);
      toast.error("Error signing in:", error);
    }
  };
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div>
        <div>
          <Helmet>
            <title>Log in</title>
            <meta name="description" content="Ma description" />
            {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
          </Helmet>
        </div>
        <NavBar />
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              {" "}
              Log in
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              {errorMsg && (
                <p
                  class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50   dark:text-red-400"
                  role="alert"
                >
                  {errorMsg}
                </p>
              )}
              <form
                className="space-y-6"
                action="#"
                method="POST"
                onSubmit={handleSignIn}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Adresse e-mail
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mot de passe
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="text-sm">
                    Nouveau Utilisateur ?
                    <a
                      href="/signin"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {" "}
                      Créer votre compte
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                  >
                    Sign in
                  </button>
                </div>
              </form>

              <div className="mt-6"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
