import { useEffect, useRef, useState } from "react";
import Footer2 from "../Components/Footer2";
import NavBar from "../Components/Navbar";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "@firebase/firestore";
import { auth, db } from "../config/firebase";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Action from "../Components/Action";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CartScreen() {
  const [userCart, setUserCart] = useState([]);
  const [products, setUserId] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    adress: "",
    nbr: "",
  });
  const formRef = useRef();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("Utilisateur connecté avec l'ID :", user.uid);
        setUserId(user.uid);
        fetchUserCart(user.uid);
      } else {
        console.log("Aucun utilisateur connecté.");
        setUserId(null);
        setUserCart([]);
      }
    });
    return unsubscribe;
  }, []);

  const fetchUserCart = async (userId) => {
    try {
      console.log("Fetching user cart for userId:", userId);
      const cartQuery = query(
        collection(db, "carts"),
        where("userId", "==", userId)
      );
      const unsubscribe = onSnapshot(cartQuery, (querySnapshot) => {
        const userCartData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserCart(userCartData);
      });
      return unsubscribe;
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du panier de l'utilisateur :",
        error
      );
    }
  };
  console.log("userCart :", userCart);

  const removeItemFromCart = async (cartId, itemId) => {
    try {
      const cartDocRef = doc(db, "carts", cartId);
      const cartDocSnapshot = await getDoc(cartDocRef);

      if (cartDocSnapshot.exists()) {
        const currentItems = cartDocSnapshot.data().items || [];

        const itemIndexToRemove = currentItems.findIndex(
          (item) => item.id === itemId
        );

        if (itemIndexToRemove !== -1) {
          currentItems.splice(itemIndexToRemove, 1);

          await updateDoc(cartDocRef, { items: currentItems });
          console.log("Item removed successfully from the cart!");
        } else {
          console.log("Item not found in the cart.");
        }
      } else {
        console.log("Cart document does not exist.");
      }
    } catch (error) {
      console.error("Error removing item from the cart:", error);
    }
  };
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = collection(db, "users");
        const q = query(userRef, where("userId", "==", user.uid));

        const unsubscribeSnapshot = onSnapshot(q, (snapshot) => {
          snapshot.forEach((doc) => {
            const userData = doc.data();
            setUser(userData);
          });
        });

        return () => unsubscribeSnapshot();
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const adress = e.target.elements.adress?.value;
    const nbr = e.target.elements.nbr?.value;

    if (!adress || !nbr) {
      console.error("Please fill in all required fields.");
      return;
    }

    try {
      // Add an order document to Firestore
      await addDoc(collection(db, "order"), {
        adress,
        nbr,
        user,
        userCart,
      });

      console.log("Order document written successfully.");

      // Remove the user's cart from Firestore
      if (userCart.length > 0) {
        const cartId = userCart[0].id; // Assuming there's only one cart per user
        const cartDocRef = doc(db, "carts", cartId);
        await deleteDoc(cartDocRef);
        console.log("User's cart deleted successfully.");
      }
    } catch (error) {
      console.error("Error writing document or deleting cart:", error);
    }

    if (formRef.current) {
      formRef.current.reset();
    }
    setFormData({
      adress: "",
      nbr: "",
    });
    navigate("/");
  };

  return (
    <div className="bg-white">
      <div>
        <Helmet>
          <title>Panier</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <NavBar />

      <main className="mx-auto max-w-2xl px-4 pt-16 pb-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Panier
        </h1>

        <section aria-labelledby="cart-heading" className=" mt-5 lg:col-span-7">
          <h2 id="cart-heading" className="sr-only">
            Items in your shopping cart
          </h2>
          {console.log("fffffffffffffff", userCart.length)}
          {userCart.length === 0 ? (
            <Action />
          ) : (
            <ul
              role="list"
              className="divide-y divide-gray-200 border-t border-b border-gray-200"
            >
              {userCart.map((cart) => (
                <div key={cart.id}>
                  <div key={cart.id}>
                    {cart.items.length === 0 ? (
                      <Action />
                    ) : (
                      <div>
                        <li className="divide-y divide-gray-200 border-t border-b border-gray-200">
                          {cart.items.map((product) => (
                            <li key={product.id} className="flex py-6">
                              <div className="flex-shrink-0">
                                <img
                                  src={product.imageUrl}
                                  alt=""
                                  className="h-24 w-24 rounded-md object-cover object-center sm:h-32 sm:w-32"
                                />
                              </div>

                              <div className="ml-4 flex flex-1 flex-col sm:ml-6">
                                <div>
                                  <div className="flex justify-between">
                                    <h4 className="text-sm">
                                      <a
                                        href={`/offre/${product.code}`}
                                        className="font-medium text-gray-700 hover:text-gray-800"
                                      >
                                        {product.nom}
                                      </a>
                                    </h4>
                                  </div>
                                  <div className="ml-4">
                                    <button
                                      type="button"
                                      className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                      onClick={() =>
                                        removeItemFromCart(cart.id, product.id)
                                      }
                                    >
                                      <span>Remove</span>
                                    </button>
                                  </div>
                                </div>

                                <div className="mt-4 flex flex-1 items-end justify-between">
                                  <p className="mt-1 text-sm text-gray-500">
                                    {product.branche}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </li>

                        <div
                          aria-labelledby="summary-heading"
                          className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
                        >
                          <h2
                            id="summary-heading"
                            className="text-lg font-medium text-gray-900"
                          >
                            Récapitulatif de la commande
                          </h2>

                          <div className="mt-6">
                            <button
                              onClick={() => setOpen(true)}
                              type="submit"
                              className="  rounded-md border border-transparent bg-slate-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                            >
                              Vérifier
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </ul>
          )}
        </section>

        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-500 sm:bg-opacity-75 sm:transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-stretch justify-center text-center sm:items-center sm:px-6 lg:px-8">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-105"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-105"
                >
                  <Dialog.Panel className="flex w-full max-w-xl transform text-left text-base transition sm:my-8">
                    <div className="relative flex w-full flex-col overflow-hidden bg-white pt-6 pb-8 sm:rounded-lg sm:pb-6 lg:py-8">
                      <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8">
                        <h2 className="text-lg font-medium text-gray-900">
                          Votre Adresse
                        </h2>
                        <button
                          type="button"
                          className="text-gray-400 hover:text-gray-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>

                      <section aria-labelledby="cart-heading">
                        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                          <div className="bg-white py-8 px-4  sm:rounded-lg sm:px-10">
                            <form
                              onSubmit={handleSubmit}
                              ref={formRef}
                              className="space-y-6"
                            >
                              <div>
                                <label
                                  htmlFor="fullName"
                                  className="block flex text-start  text-sm font-medium text-gray-700"
                                >
                                  Nom Copmlet
                                </label>
                                <div className="mt-1">
                                  <input
                                    id="fullName"
                                    name="fullName"
                                    type="fullName"
                                    autoComplete="fullName"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="adress"
                                  className="block flex text-start  text-sm font-medium text-gray-700"
                                >
                                  Address
                                </label>
                                <div className="mt-1">
                                  <input
                                    id="adress"
                                    name="adress"
                                    type="adress"
                                    autoComplete="address"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>

                              <div>
                                <label
                                  htmlFor="nbr"
                                  className="block text-sm text-start font-medium text-gray-700"
                                >
                                  Numero Téléphone
                                </label>
                                <div className="mt-1">
                                  <input
                                    id="nbr"
                                    name="nbr"
                                    type="nbr"
                                    autoComplete="tel"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>

                              <div>
                                <button
                                  type="submit"
                                  className="mt-10 flex w-full justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                                >
                                  Valider
                                </button>
                              </div>
                            </form>
                          </div>{" "}
                        </div>
                      </section>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </main>

      <Footer2 />
    </div>
  );
}
